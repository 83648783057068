export const getFavourites = (filters) => async () => {
  let query = Object.keys(filters)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(filters[k]))
    .join("&")

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/favories?` + query,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    }
  )
  const data = await response.json()
  return data
}

export const addFavourite = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/favories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    },
    body: JSON.stringify({
      follower_id: id,
    }),
  })
  const data = await response.json()
  return data
}

export const deleteFavourite = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/favories`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    },
    body: JSON.stringify({
      follower_id: id,
    }),
  })
  const data = await response.json()
  return data
}
