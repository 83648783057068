import { createMuiTheme } from "@material-ui/core/styles"

const TypographyRootColor = "#091E42"
const themeInstance = createMuiTheme({
  typography: {
    fontFamily: "sofia-pro",
    allVariants: {
      color: TypographyRootColor,
    },
    h1: {
      fontSize: 54,
      fontWeight: 200,
      lineHeight: "82px",
    },
    h2: {
      fontSize: 36,
      fontWeight: 500,
      lineHeight: "56px",
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "20px",
    },
    h6: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "20px",
      color: "rgba(9, 30, 66, 0.48)",
      marginBottom: "8px",
    },
    body1: {
      fontSize: 16,
      lineHeight: "25px",
      fontWeight: 400,
    },
  },
  shadows: ["none"],
  palette: {
    primary: {
      main: "#103DF5",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: "6px 10px",
        textTransform: "none",
      },
      contained: {
        backgroundColor: "rgba(16, 61, 245, 0.2)",
        color: "#103DF5",

        "&:hover": {
          backgroundColor: "#103DF5",
          color: "#FFFFFF",
        },
      },
      containedSecondary: {
        backgroundColor: "rgba(245, 16, 71, 0.2)",
        color: "#F51047",
      },
    },
    MuiChip: {
      labelSmall: {
        fontSize: 10,
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "rgba(9, 30, 66, 0.08)",
        borderWidth: "1px",
      },
      root: {
        fontSize: 14,
        lineHeight: "21px",
      },
      input: {
        padding: "10px 12px",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
        fontWeight: 300,
      },
    },
    MuiTextField: {
      root: {
        fontSize: 14,
        lineHeight: "21px",
      },
    },
    MuiSlider: {
      marked: {
        marginBottom: "10px",
      },
      track: {
        height: "6px",
        backgroundColor: "#091E42",
      },
      thumb: {
        backgorundColor: "#E6E9EC",
      },
      mark: {
        display: "none",
      },
      valueLabel: {
        fontSize: 10,
        fontWeight: 400,
      },
      markLabel: {
        display: "none",
      },
    },
    MuiListSubheader: {
      sticky: {
        position: "initial",
      },
      root: {
        fontSize: "12px",
        lineHeight: "22px",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(9, 30, 66, 0.95)",
      },
    },
    MuiRadio: {
      root: {
        color: "rgba(9, 30, 66, 0.08)",
      },
    },
  },
})

export const theme = themeInstance
