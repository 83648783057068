// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Search_Container__1k3M0 {\n  padding: 14px;\n  background: white;\n  border-radius: 8px;\n  width: 100%;\n  height: -moz-fit-content;\n  height: fit-content;\n  display: grid;\n  grid-template-areas: \"h h\" \"input button\";\n  grid-template-columns: 1fr 100px;\n  grid-row-gap: 16px;\n  grid-column-gap: 16px; }\n\n.Search_Heading__1K8Zr {\n  grid-area: h; }\n\n.Search_Search__36N-I {\n  grid-area: input; }\n\n.Search_Button__1lYYi {\n  grid-area: button; }\n", "",{"version":3,"sources":["webpack://src/pages/Main/SearchStartups/Search/Search.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,wBAAmB;EAAnB,mBAAmB;EACnB,aAAa;EACb,yCAAyC;EACzC,gCAAgC;EAChC,kBAAkB;EAClB,qBAAqB,EAAA;;AAEvB;EACE,YAAY,EAAA;;AAGd;EACE,gBAAgB,EAAA;;AAGlB;EACE,iBAAiB,EAAA","sourcesContent":[".Container {\n  padding: 14px;\n  background: white;\n  border-radius: 8px;\n  width: 100%;\n  height: fit-content;\n  display: grid;\n  grid-template-areas: \"h h\" \"input button\";\n  grid-template-columns: 1fr 100px;\n  grid-row-gap: 16px;\n  grid-column-gap: 16px;\n}\n.Heading {\n  grid-area: h;\n}\n\n.Search {\n  grid-area: input;\n}\n\n.Button {\n  grid-area: button;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "Search_Container__1k3M0",
	"Heading": "Search_Heading__1K8Zr",
	"Search": "Search_Search__36N-I",
	"Button": "Search_Button__1lYYi"
};
export default ___CSS_LOADER_EXPORT___;
