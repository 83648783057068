import React, { useState, useRef } from "react"
import {
  Button,
  Typography,
  Modal,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { Tag, Select, Slider } from "src/components/common"
import TextField from "@material-ui/core/TextField"
import {
  EstabilishedMarks,
  getMarkLabel,
} from "src/components/common/Slider/sliderMarks"
import {
  editFilters,
  //saveSearchCriteria,
  setCategory,
  setListScoutings,
  editWatching,
} from "src/redux/startups/actions"
import { getSidebarSettings } from "src/redux/selectors"
import DetailsIcon from "@material-ui/icons/Details"
import classes from "./Sidebar.module.scss"
import { ScoutingApi } from "src/api"
import { makeStyles } from "@material-ui/core/styles"
import { setSpecificListScoutings } from "../../../../redux/startups/actions"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { useSnackbar } from "notistack"

import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "432px",
    outline: "none",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    padding: "16px",
  },
}))

const formStatus = {
  initial: "initial",
  sending: "sending",
  error: "error",
}

export default function Sidebar() {
  const dispatch = useDispatch()
  const scouting = useSelector((state) => state.startups.scouting)

  const startupListScouting = useSelector((state) => {
    return state.startups.startupListScouting
  })

  const [catTags, setCatTags] = useState([])
  const [subcatTags, setSubCatTags] = useState([])
  const [enabledClick, setEnabledClick] = useState([])
  const [statusValidation, setStatusValidation] = useState(formStatus.initial)
  const [scoutingValue, setScoutingValue] = useState([])
  const modalClasses = useStyles()
  const [open, setOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const emailRef = useRef(null)

  function showSuccess() {
    enqueueSnackbar("Startup shared successfully.", {
      variant: "success",
      autoHideDuration: 1000,
    })
  }

  function showError() {
    enqueueSnackbar("We couldn't share this startup, please try again later.", {
      variant: "error",
      autoHideDuration: 1000,
    })
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function onScountingChange(event, newInputValue) {
    setScoutingValue(Number(newInputValue))
  }

  async function onSendClick() {
    const inputValue = emailRef.current.value

    try {
      if (inputValue) {
        setStatusValidation(formStatus.sending)
        const resultStartups = await ScoutingApi.postSpecificScouting(
          inputValue,
          startupListScouting
        )

        await dispatch(setSpecificListScoutings(resultStartups))

        if (
          resultStartups &&
          resultStartups.json &&
          resultStartups.json.scouting.length
        ) {
          await dispatch(setListScoutings(resultStartups.json.scouting))
        }
        setStatusValidation(formStatus.initial)

        showSuccess()
        handleClose()
      } else {
        setStatusValidation(formStatus.sending)
        const resultStartups = await ScoutingApi.postSpecificScoutingWithoutInput(
          scoutingValue,
          startupListScouting
        )
        await dispatch(setSpecificListScoutings(resultStartups))
        setStatusValidation(formStatus.initial)

        showSuccess()
        handleClose()
      }
    } catch (err) {
      setStatusValidation(formStatus.error)
      showError()
    }
  }
  //const inputRef = useRef()
  const buttonsDisabled = statusValidation !== formStatus.initial
  //const optionsScouting = scouting && [...scouting.map(({ name }) => name)]

  const { mainTags, countries, technology, customerSegment } = useSelector(
    getSidebarSettings
  )
  //const inputRef = useRef()

  const filters = useSelector((state) => state.startups.filters)
  const tagsIncoming = useSelector((state) => state.startups.tags)

  let insrttagArray = []
  mainTags &&
    mainTags.map((word) => {
      localStorage.getItem("setRoleUser") &&
      localStorage.getItem("setRoleUser").includes(word.parent)
        ? insrttagArray && insrttagArray.push(word.parent)
        : ""
      word.subTags.map((item) =>
        item.map((it) =>
          localStorage.getItem("setRoleUser") &&
          localStorage.getItem("setRoleUser").includes(it)
            ? insrttagArray.push(it)
            : ""
        )
      )
    })
  tagsIncoming &&
    tagsIncoming.mainTags &&
    tagsIncoming.mainTags.map((parentTag) => {
      parentTag.subTags.map((subTag) => {
        if (insrttagArray.includes(subTag.name)) {
          const index = insrttagArray.indexOf(subTag.name)
          insrttagArray[index] = [subTag.name, subTag.color]
        }
      })
    })

  let insrttagArrayTech = []
  technology &&
    technology.filter((word) => {
      localStorage.getItem("setRoleUser") &&
      localStorage.getItem("setRoleUser").includes(word)
        ? insrttagArrayTech && insrttagArrayTech.push(word)
        : ""
    })

  tagsIncoming &&
    tagsIncoming.mainTags &&
    tagsIncoming.mainTags.map((parentTag) => {
      parentTag.subTags.map((subTag) => {
        if (insrttagArrayTech.includes(subTag.name)) {
          const index = insrttagArrayTech.indexOf(subTag.name)
          insrttagArrayTech[index] = [subTag.name, subTag.color]
        }
      })
    })

  let insrttagArrayCus = []
  customerSegment &&
    customerSegment.filter((word) => {
      localStorage.getItem("setRoleUser") &&
      localStorage.getItem("setRoleUser").includes(word)
        ? insrttagArrayCus && insrttagArrayCus.push(word)
        : ""
    })

  tagsIncoming &&
    tagsIncoming.mainTags &&
    tagsIncoming.mainTags.map((parentTag) => {
      parentTag.subTags.map((subTag) => {
        if (insrttagArrayCus.includes(subTag.name)) {
          const index = insrttagArrayCus.indexOf(subTag.name)
          insrttagArrayCus[index] = [subTag.name, subTag.color]
        }
      })
    })

  const {
    categories,
    createdRange,
    country,
    status = { active: true, merged: true, dead: true },
    analyse = false,
  } = filters

  function editCurrentFilters(filters) {
    dispatch(editWatching("initial"))
    dispatch(editFilters(filters))
  }

  function onChangeCreatedRange(value) {
    editCurrentFilters({ createdRange: value })
  }
  // function onChangeFunraisingRange(value) {
  //   editCurrentFilters({ fundraisingRange: value })
  // }

  function onSelectTag(tag) {
    dispatch(editWatching("initial"))
    dispatch(setCategory(tag))
  }

  function onCountryChange(event, newInputValue) {
    editCurrentFilters({ country: newInputValue })
  }

  function isActive(tag, number) {
    //dispatch(setCategory(tag));
    return number === 0
      ? categories.includes(tag)
      : number === 1
      ? catTags.includes(tag) || categories.includes(tag)
      : number === 2
      ? subcatTags.includes(tag) || categories.includes(tag)
      : ""
  }

  // function openModal() {
  //   toggleModal(true)
  // }

  // function saveToDashboard() {
  // dispatch(saveSearchCriteria(inputRef.current.input.value))
  //toggleModal(false)
  // }

  function resetFilters() {
    document.getElementById("TextFieldValueSearch").value = ""
    setCatTags([])
    setEnabledClick([])
    setSubCatTags([])
    editCurrentFilters({
      country: [],
      analysis: false,
      status: { active: true, merged: true, dead: true },
      categories: [],
      createdRange: [2000, 2021],
      search: "",
      page: 1,
    })
  }

  function handleCheckboxChange(type) {
    editCurrentFilters({ status: { ...status, [type]: !status[type] } })
  }

  function handleCheckboxChangeAstoryaAnalysis(value) {
    if (value === true) {
      editCurrentFilters({ analyse: false })
    } else {
      editCurrentFilters({ analyse: true })
    }
  }

  // function handleCheckboxChangeEmployee(type) {
  //   editCurrentFilters({ status: { ...status, [type]: !status[type] } })
  // }

  async function handletagsrefresh(tags) {
    const parents = await catTags.indexOf(tags.parent)
    const resultSlice = await catTags[parents]
    return setCatTags(catTags.filter((word) => word !== resultSlice))
  }

  function arraySplice(array, number) {
    const index = array.indexOf(number)
    array.splice(index, 1)
    return array
  }

  const options = countries && [...countries.map(({ name }) => name)]

  let searchUrl = window.location.search
  let params = new URLSearchParams(searchUrl)
  let tokenParams = params.get("tokenShow")
  const colorWeight = ["blue", "purple", "pink", "green", "orange", "red"]

  return (
    <div className={classes.Container}>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <Typography variant="h5"> Advanced criteria </Typography>
        <span
          onClick={resetFilters}
          style={{
            color: "#F51047",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: 800,
            padding: "5px",
          }}
        >
          Clear all
        </span>
      </div>
      <section className={classes.Section}>
        <Typography variant="h6"> Tags </Typography>
        <div>
          {localStorage.getItem("setRoleUser") === "" || !!tokenParams
            ? tagsIncoming.mainTags &&
              tagsIncoming.mainTags.map((tags) => (
                <div key={`mainTag${tags.parent}`} className={classes.TagsWrapper}>
                  {tags.parent !== "CustomerSegment" &&
                  tags.parent !== "Technology" ? (
                    <React.Fragment>
                      <Tag
                        color="blue"
                        active={isActive(tags.parent, 1)}
                        parent={true}
                        onClick={() =>
                          enabledClick.includes(tags.parent)
                            ? ""
                            : catTags.includes(tags.parent)
                            ? catTags.length === 1
                              ? setCatTags([])
                              : handletagsrefresh(tags)
                            : setCatTags([...catTags, tags.parent])
                        }
                        block={true}
                      >
                        {tags.parent} <DetailsIcon style={{ fontSize: "8px" }} />
                      </Tag>
                      {catTags &&
                        catTags.includes(tags.parent) &&
                        // ||
                        // tags.subTags.some((subCollection) =>
                        // subCollection && subCollection.includes(catTags[0])
                        // ))
                        tags.subTags
                          .sort(function (a, b) {
                            if (a && b) {
                              if (
                                colorWeight.findIndex((item) => item === a.color) <
                                colorWeight.findIndex((item) => item === b.color)
                              )
                                return -1
                              if (
                                colorWeight.findIndex((item) => item === a.color) >
                                colorWeight.findIndex((item) => item === b.color)
                              )
                                return 1
                              return 0
                            } else {
                              return -1
                            }
                          })
                          .map((col) => (
                            <Tag
                              key={`mainSubTag${col.id}`}
                              color={col.color}
                              active={isActive(col.name, 2)}
                              onClick={() => {
                                onSelectTag(col.name)
                                setEnabledClick(
                                  isActive(col.name, 2)
                                    ? enabledClick.length === 1
                                      ? []
                                      : arraySplice(enabledClick, tags.parent)
                                    : [...enabledClick, tags.parent]
                                )
                                return subcatTags.includes(col.name)
                                  ? subcatTags.length === 1
                                    ? setSubCatTags([])
                                    : setSubCatTags(
                                        arraySplice(subcatTags, col.name)
                                      )
                                  : setSubCatTags([...subcatTags, col.name])
                              }}
                            >
                              {col.name}
                            </Tag>
                          ))}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              ))
            : insrttagArray.map((tags) => (
                <div key={`mainTag${tags}`} className={classes.TagsWrapper}>
                  <Tag
                    color={tags[1]}
                    active={isActive(tags, 2)}
                    parent={true}
                    onClick={() => onSelectTag(tags)}
                    block={true}
                  >
                    {tags[0]}
                  </Tag>
                </div>
              ))}
        </div>
      </section>

      <section className={classes.Section}>
        <Typography variant="h6">Customer segment</Typography>
        <div className={classes.TagsWrapper}>
          {localStorage.getItem("setRoleUser") === "" || !!tokenParams
            ? tagsIncoming.mainTags &&
              tagsIncoming.mainTags.map((tag) => {
                return tag.parent === "CustomerSegment" ? (
                  tag.subTags.map((tag) => (
                    <Tag
                      key={`customerTag${tag.name}`}
                      active={isActive(tag.name, 1)}
                      onClick={() => onSelectTag(tag.name)}
                      color={tag.color}
                      //color={getColor(tag.color)}
                    >
                      {tag.name}
                    </Tag>
                  ))
                ) : (
                  <div></div>
                )
              })
            : insrttagArrayCus.map((tag) => (
                <Tag
                  key={`customerTag${tag}`}
                  active={isActive(tag, 1)}
                  onClick={() => onSelectTag(tag)}
                  color={tag[1]}
                >
                  {tag[0]}
                </Tag>
              ))}
        </div>
      </section>

      <section className={classes.Section}>
        <Typography variant="h6">Technology</Typography>
        <div className={classes.TagsWrapper}>
          {localStorage.getItem("setRoleUser") === "" || !!tokenParams
            ? tagsIncoming.mainTags &&
              tagsIncoming.mainTags.map((tag) => {
                return tag.parent === "Technology" ? (
                  tag.subTags.map((tag) => (
                    <Tag
                      key={`techTag${tag.name}`}
                      active={isActive(tag.name, 1)}
                      onClick={() => onSelectTag(tag.name)}
                      color={tag.color}
                      //color="orange"
                    >
                      {tag.name}
                    </Tag>
                  ))
                ) : (
                  <div></div>
                )
              })
            : insrttagArrayTech.map((tag) => (
                <Tag
                  key={`techTag${tag.name}`}
                  active={isActive(tag, 1)}
                  onClick={() => onSelectTag(tag)}
                  color={tag[1]}
                >
                  {tag[0]}
                </Tag>
              ))}
        </div>
      </section>

      <section className={classes.Section}>
        <Typography variant="h6">Country</Typography>
        <div className={classes.TagsWrapper}>
          {country.map((tag) => (
            <Tag
              key={`techTag${tag}`}
              active={isActive(tag, 0)}
              color="grey"
              style={{ margin: "5px" }}
            >
              {tag}
            </Tag>
          ))}
        </div>
        <FormControl variant="outlined" style={{ marginTop: "10px" }} fullWidth>
          <Select
            multiple={true}
            onChange={onCountryChange}
            options={options}
            value={country}
          />
        </FormControl>
      </section>
      <section className={classes.Section}>
        <Typography variant="h6">Status</Typography>
        <section className={classes.Section}>
          <FormControlLabel
            control={
              <Checkbox
                id="analyse"
                name="analyse"
                checked={analyse}
                onChange={() => handleCheckboxChangeAstoryaAnalysis(analyse)}
                color="primary"
              />
            }
            label="astorya.io analysis"
          ></FormControlLabel>
        </section>
        <FormControlLabel
          control={
            <Checkbox
              id="active"
              name="active"
              checked={status.active}
              onChange={() => handleCheckboxChange("active")}
              color="primary"
            />
          }
          label="Active"
        ></FormControlLabel>
        <FormControlLabel
          control={
            <Checkbox
              id="merged"
              name="merged"
              checked={status.merged}
              onChange={() => handleCheckboxChange("merged")}
              color="primary"
            />
          }
          label="Merged"
        ></FormControlLabel>
        <FormControlLabel
          control={
            <Checkbox
              id="dead"
              name="dead"
              checked={status.dead}
              onChange={() => handleCheckboxChange("dead")}
              color="primary"
            />
          }
          label="Dead"
        ></FormControlLabel>
      </section>

      <Slider
        title="Established"
        min={EstabilishedMarks[0].value}
        max={EstabilishedMarks[EstabilishedMarks.length - 1].value}
        marks={EstabilishedMarks}
        valueLabelFormat={(value) => getMarkLabel(value, EstabilishedMarks)}
        onChange={onChangeCreatedRange}
        defaultValue={createdRange}
      />

      {/* <Button
        variant="contained"
        color="primary"
        className={classes.Button}
        onClick={openModal}
        fullWidth
      >
        Save criteria to dashboard
      </Button> */}
      {localStorage.getItem("setTokenRoleSoucing") === "true" ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          style={{ width: "100%" }}
        >
          Save to Scouting
        </Button>
      ) : (
        ""
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={modalClasses.modal}
        open={open}
        BackdropComponent={Backdrop}
        disableBackdropClick
        BackdropProps={{
          timeout: 350,
        }}
      >
        <Fade in={open} onExited={handleClose}>
          <div className={modalClasses.paper} data-test-id="share-modal-content">
            <Typography variant="h4">Save into your Scouting</Typography>
            <div
              style={{
                marginTop: "20px",
                minHeight: "100px",
                display: "grid",
                gridRowGap: "14px",
                gridTemplateRows: "max-content",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body2">Create a new Scouting:</Typography>

              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  inputRef={emailRef}
                  placeholder="Scouting Name"
                  fullWidth
                />
                <Typography variant="body2" style={{ marginTop: "20px" }}>
                  Or choose one from:
                </Typography>

                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="scouting"
                    name="scouting"
                    value={scoutingValue}
                    onChange={onScountingChange}
                  >
                    {scouting.map(({ name, list_scouting_id }) => (
                      <FormControlLabel
                        key={list_scouting_id}
                        value={list_scouting_id}
                        control={<Radio />}
                        label={name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </FormControl>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridColumnGap: "8px",
                  width: "100%",
                  marginTop: "18px",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                  fullWidth
                  disabled={buttonsDisabled}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSendClick}
                  disabled={buttonsDisabled}
                  fullWidth
                >
                  {buttonsDisabled ? "Connecting" : "Connect"}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
