import { viewPages } from "./view/viewPages"
import appStates from "./view/appStates"
import {
  EstabilishedMarks,
  EmployeesMarks,
  getMinMax,
} from "src/components/common/Slider/sliderMarks"

let search = window.location.search
let params = new URLSearchParams(search)
let tokenParams = params.get("tokenShow")

const emailSetUp = !!localStorage.getItem("email")
const activePage = emailSetUp
  ? localStorage.getItem("setTokenRoleFrontFilters") === "true" ||
    localStorage.getItem("setTokenRoleFrontSearch") === "true" ||
    localStorage.getItem("setTokenRoleFrontList") === "true"
    ? viewPages.SEARCH
    : viewPages.DASHBOARD
  : tokenParams
  ? viewPages.SEARCH
  : viewPages.KPI

export const initState = (window.Cypress && window.initState) || {
  startups: {
    startupListScouting: [],
    startupList: [],
    scouting: [],
    specificScouting: [],
    userSpecificScouting: [],
    specificListID: 0,
    tags: {},
    tagsCollections: [],
    filteredStartupList: [],
    numberStartups: 0,
    similarStartups: [],
    nbStartups: 0,
    filters: {
      categories: [],
      analyse: false,
      search: "",
      country: [],
      page: 1,
      status: { active: true, merged: true, dead: true },
      createdRange: getMinMax(EstabilishedMarks),
      employeesRange: getMinMax(EmployeesMarks),
    },
    favourites: [],
    requestInfos: [],
    waitSearch: "",
    requestedStartups: [],
    savedSearches: [
      { title: "", category: "", results: [] },
      { title: "", category: "", results: [] },
      { title: "", category: "", results: [] },
    ],
  },
  view: {
    activeTab: activePage,
    sidebarSettings: {},
    welcomePanelActive: false,
    loggedIn: false,
    ready: false,
    user: {
      email: "",
      avatarUrl: null,
      userType: "",
      mainTag: "",
    },
    startupModalId: null,
    appState: appStates.loading,
  },
}
