import React, { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import clsx from "clsx"

import UserDropdown from "./UserDropdown"
import { setActivePage } from "src/redux/actions"
import logo from "src/assets/images/astoryalogo.png"
import { getActiveTab, isLoggedIn } from "src/redux/selectors"
import { viewPages } from "src/redux/view/viewPages"

import classes from "./Navbar.module.scss"

export function Navbar({ activeTab = "", setNewTab = () => {}, loggedIn = false }) {
  return (
    <nav className={classes.NavContainer}>
      <a href="https://www.scouting.astorya.io">
        <img alt="" src={logo} className={classes.Logo} />
      </a>
      {loggedIn ? (
        <React.Fragment>
          <ul className={classes.PageList}>
            {localStorage.getItem("setTokenRoleFrontFilters") === "true" ||
            localStorage.getItem("setTokenRoleFrontSearch") === "true" ||
            localStorage.getItem("setTokenRoleFrontList") === "true" ? (
              <li
                onClick={() => setNewTab(viewPages.SEARCH)}
                className={clsx(activeTab === viewPages.SEARCH && classes.ActiveTab)}
              >
                Scouting
              </li>
            ) : (
              ""
            )}
            {localStorage.getItem("setTokenRoleFrontSavedSearch") === "true" ||
            localStorage.getItem("setTokenRoleFrontSavedFavories") === "true" ? (
              <li
                onClick={() => setNewTab(viewPages.DASHBOARD)}
                className={clsx(
                  activeTab === viewPages.DASHBOARD && classes.ActiveTab
                )}
              >
                Research
              </li>
            ) : (
              ""
            )}
            {localStorage.getItem("setTokenRoleSoucing") === "true" ? (
              <li
                onClick={() => setNewTab(viewPages.SCOUTING)}
                className={clsx(
                  activeTab === viewPages.SCOUTING && classes.ActiveTab
                )}
              >
                Lists
              </li>
            ) : (
              ""
            )}
            <li
              onClick={() => setNewTab(viewPages.KPI)}
              className={clsx(activeTab === viewPages.KPI && classes.ActiveTab)}
            >
              KPI
            </li>
          </ul>

          <UserDropdown />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ul className={classes.PageList}>
            <li
              onClick={() => setNewTab(viewPages.KPI)}
              className={clsx(activeTab === viewPages.KPI && classes.ActiveTab)}
            >
              Monitor KPI
            </li>
            <li
              onClick={() => setNewTab(viewPages.SEARCH)}
              className={clsx(activeTab === viewPages.SEARCH && classes.ActiveTab)}
            >
              Search startups
            </li>
            <li
              onClick={() => setNewTab(viewPages.DASHBOARD)}
              className={clsx(
                activeTab === viewPages.DASHBOARD && classes.ActiveTab
              )}
            >
              Research
            </li>
          </ul>

          <ul className={classes.PageListRight}>
            <li
              onClick={() => setNewTab(viewPages.SIGN)}
              className={clsx(activeTab === viewPages.SIGN && classes.ActiveTab)}
            >
              Sign In
            </li>
          </ul>
        </React.Fragment>
      )}
    </nav>
  )
}

const NavbarHoc = () => {
  const dispatch = useDispatch()
  const activeTab = useSelector(getActiveTab)

  const setNewTab = useCallback((tab) => dispatch(setActivePage(tab)))

  const loggedIn = useSelector(isLoggedIn)

  return <Navbar loggedIn={loggedIn} setNewTab={setNewTab} activeTab={activeTab} />
}

export default NavbarHoc
