import React, { useRef } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Typography, TextField, Button } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { editFilters, editWatching } from "src/redux/startups/actions"

import classes from "./Search.module.scss"

export default function Search() {
  const dispatch = useDispatch()
  const inputRef = useRef()

  //const search = useSelector(getDescriptionFromFilters)

  const filters = useSelector((state) => state.startups.filters)

  function onSearch() {
    if (inputRef.current) dispatch(editWatching("initial"))
    dispatch(editFilters({ search: inputRef.current.value }))
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      onSearch()
    }
  }

  return (
    <div className={classes.Container}>
      <Typography className={classes.Heading} variant="h5">
        Search startups
      </Typography>

      <TextField
        className={classes.Search}
        id="TextFieldValueSearch"
        variant="outlined"
        placeholder="Tag+Country+Date"
        inputRef={inputRef}
        defaultValue={filters.search}
        onKeyDown={handleKeyDown}
        fullWidth
      />

      <Button
        className={classes.Button}
        onClick={onSearch}
        variant="contained"
        color="primary"
        startIcon={<SearchIcon />}
        disableElevation
      >
        Search
      </Button>
    </div>
  )
}
