const defaultSettings = {
  mainTags: [],
  customerSegment: [],
  technology: [],
  countries: [],
  minCreatedYear: 2010,
  maxCreatedYear: 2021,
  minFundraisingSize: 0,
  maxFundraisingSize: 100,
  minEmployees: 0,
  maxEmployees: 100,
  fundraisingRounds: [],
}

export const deserializeSidebarSettings = (data) => {
  return { ...defaultSettings, ...data }
}
