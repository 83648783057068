// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignIn_CardWrapper__2TRIG {\n  padding: 16px;\n  border-radius: 8px;\n  background-color: #ffffff; }\n\n.SignIn_FormWrapper__b05Ml {\n  margin-top: 20px;\n  display: grid;\n  grid-template-columns: 100%;\n  grid-row-gap: 16px; }\n\n.SignIn_Extras__27xHU {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  font-size: 12px;\n  padding: 16px; }\n\n.SignIn_ButtonGroupWrapper__XVm9g {\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n  .SignIn_ButtonGroupWrapper__XVm9g > a {\n    margin-left: 2px; }\n\n.SignIn_SmallerPadding__2bpht label {\n  font-size: 0.9rem;\n  font-weight: 300;\n  transform: translate(14px, 12px) scale(1); }\n\n.SignIn_SmallerPadding__2bpht input {\n  padding: 10px 12px; }\n", "",{"version":3,"sources":["webpack://src/pages/Main/SignIn/SignIn.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,yBAAyB,EAAA;;AAG3B;EACE,gBAAgB;EAChB,aAAa;EACb,2BAA2B;EAC3B,kBAAkB,EAAA;;AAGpB;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;EACjB,eAAe;EACf,aAAa,EAAA;;AAGf;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;EAHrB;IAKI,gBAAgB,EAAA;;AAIpB;EAEI,iBAAiB;EACjB,gBAAgB;EAChB,yCAAyC,EAAA;;AAJ7C;EAQI,kBAAkB,EAAA","sourcesContent":[".CardWrapper {\n  padding: 16px;\n  border-radius: 8px;\n  background-color: #ffffff;\n}\n\n.FormWrapper {\n  margin-top: 20px;\n  display: grid;\n  grid-template-columns: 100%;\n  grid-row-gap: 16px;\n}\n\n.Extras {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  font-size: 12px;\n  padding: 16px;\n}\n\n.ButtonGroupWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  > a {\n    margin-left: 2px;\n  }\n}\n\n.SmallerPadding {\n  label {\n    font-size: 0.9rem;\n    font-weight: 300;\n    transform: translate(14px, 12px) scale(1);\n  }\n\n  input {\n    padding: 10px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CardWrapper": "SignIn_CardWrapper__2TRIG",
	"FormWrapper": "SignIn_FormWrapper__b05Ml",
	"Extras": "SignIn_Extras__27xHU",
	"ButtonGroupWrapper": "SignIn_ButtonGroupWrapper__XVm9g",
	"SmallerPadding": "SignIn_SmallerPadding__2bpht"
};
export default ___CSS_LOADER_EXPORT___;
