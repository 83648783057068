// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar_Container__IBv4m {\n  grid-row: 1/3;\n  padding: 15px;\n  background: white;\n  border-radius: 10px;\n  height: -moz-fit-content;\n  height: fit-content; }\n\n.Sidebar_TagsWrapper__1Se6A {\n  display: inline-flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: wrap; }\n\n.Sidebar_TagTitle__2n1f6 {\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: 0em; }\n\n.Sidebar_Section__2jN97 {\n  margin: 10px 0; }\n\n.Sidebar_Button__HVtYO {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 18px; }\n", "",{"version":3,"sources":["webpack://src/pages/Main/Scouting/Sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,wBAAmB;EAAnB,mBAAmB,EAAA;;AAGrB;EACE,oBAAoB;EACpB,2BAA2B;EAC3B,uBAAuB;EACvB,eAAe,EAAA;;AAGjB;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB,EAAA;;AAGrB;EACE,cAAc,EAAA;;AAGhB;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB,EAAA","sourcesContent":[".Container {\n  grid-row: 1/3;\n  padding: 15px;\n  background: white;\n  border-radius: 10px;\n  height: fit-content;\n}\n\n.TagsWrapper {\n  display: inline-flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: wrap;\n}\n\n.TagTitle {\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: 0em;\n}\n\n.Section {\n  margin: 10px 0;\n}\n\n.Button {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "Sidebar_Container__IBv4m",
	"TagsWrapper": "Sidebar_TagsWrapper__1Se6A",
	"TagTitle": "Sidebar_TagTitle__2n1f6",
	"Section": "Sidebar_Section__2jN97",
	"Button": "Sidebar_Button__HVtYO"
};
export default ___CSS_LOADER_EXPORT___;
