// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_Main__3C81A {\n  padding-top: 70px;\n  background: #f4f5f7;\n  min-height: calc(100vh);\n  display: flex;\n  justify-content: center;\n  align-items: flex-start; }\n", "",{"version":3,"sources":["webpack://src/components/Layout/Layout.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,uBAAuB;EACvB,uBAAuB,EAAA","sourcesContent":[".Main {\n  padding-top: 70px;\n  background: #f4f5f7;\n  min-height: calc(100vh);\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Main": "Layout_Main__3C81A"
};
export default ___CSS_LOADER_EXPORT___;
