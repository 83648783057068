import React, { useState } from "react"
import classes from "./Navbar.module.scss"
import { LoginApi } from "src/api"
import { useDispatch, useSelector } from "react-redux"
import { signOut } from "src/redux/actions"
import Popover from "@material-ui/core/Popover"
import MenuItem from "@material-ui/core/MenuItem"
import CircularProgress from "@material-ui/core/CircularProgress"
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded"
import { viewStoreSelector } from "src/redux/selectors"

const MenuContent = () => {
  const dispatch = useDispatch()
  const [loggingOut, triggerLogging] = useState(false)

  async function logOut() {
    triggerLogging(true)
    await LoginApi.signOut()()
    window.localStorage.removeItem("email")
    window.localStorage.removeItem("password")
    window.localStorage.setItem("keepLoggedIn", false)
    dispatch(signOut())
  }
  return (
    <React.Fragment>
      <MenuItem onClick={logOut}>
        Log out
        {loggingOut && (
          <CircularProgress style={{ marginLeft: "10px" }} size="1rem" />
        )}
      </MenuItem>
    </React.Fragment>
  )
}

function UserDropdown() {
  const [anchorEl, setAnchorEl] = useState(null)
  const user = useSelector(viewStoreSelector).user

  const { email = "" } = user
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <div
      overlay={<MenuContent />}
      trigger="click"
      placement="topRight"
      className={classes.Auth}
    >
      <div
        onClick={handleClick}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <p>{email}</p>
        <KeyboardArrowDownRoundedIcon
          style={{
            transform: open && "rotate(180deg)",
            transition: "transform 0.3s",
          }}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuContent />
      </Popover>
    </div>
  )
}

export default UserDropdown
