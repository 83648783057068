import React from "react"
import PropTypes from "prop-types"

function SVGIcon({ src = "", alt = "", style = {}, onClick = function () {} }) {
  return (
    <img
      src={src}
      alt={alt}
      onClick={onClick}
      style={{ ...style, cursor: "pointer" }}
    />
  )
}

SVGIcon.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

export default SVGIcon
