import React from "react"
import classes from "./IconButtons.module.scss"
import twitter from "src/assets/images/twitter.svg"
import website from "src/assets/images/website.svg"
import linkedin from "src/assets/images/linkedin.svg"
import facebook from "src/assets/images/facebook.svg"
import SVGIcon from "src/components/common/SVGIcon"
import ActiveIcon from "src/assets/images/active_icon.svg"
import DeadIcon from "src/assets/images/dead_icon.svg"
import MergedIcon from "src/assets/images/merged_icon.svg"
import crunchbase from "src/assets/images/crunchbase.svg"
import google from "src/assets/images/google.svg"
import founder from "src/assets/images/founder.svg"

export function MoreButton({ ...rest }) {
  //active = false,
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.IconButtonBase}
      {...rest}
    >
      <rect width="24" height="24" rx="4" fill="#091E42" fillOpacity="0.04" />
      <circle cx="7" cy="12" r="1" fill="#091E42" fillOpacity="0.32" />
      <circle cx="12" cy="12" r="1" fill="#091E42" fillOpacity="0.32" />
      <circle cx="17" cy="12" r="1" fill="#091E42" fillOpacity="0.32" />
    </svg>
  )
}

export function PencilButton({ ...rest }) {
  //active = false,
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.IconButtonBase}
      {...rest}
    >
      <rect width="24" height="24" rx="4" fill="#091E42" fillOpacity="0.04" />
      <g opacity="0.32" clipPath="url(#clip0)">
        <path
          d="M6.38523 18.0001C6.28554 18.0001 6.1878 17.9609 6.1156 17.8882C6.02094 17.7935 5.98274 17.6561 6.01535 17.5264L6.73172 14.6511C6.74849 14.5839 6.78315 14.5223 6.83197 14.4735L14.8963 6.40918C15.4417 5.86369 16.3296 5.86369 16.8751 6.40918L17.5945 7.12862C18.14 7.67412 18.14 8.56201 17.5945 9.10741L9.53029 17.1717C9.48137 17.2205 9.41979 17.2557 9.35271 17.272L6.47737 17.9883C6.44681 17.9965 6.41625 18.0001 6.38523 18.0001ZM7.44613 14.938L6.90883 17.0944L9.06519 16.5571L17.0552 8.5676C17.303 8.31977 17.303 7.9158 17.0552 7.66797L16.3357 6.94853C16.0874 6.70024 15.6834 6.70071 15.4361 6.94853L7.44613 14.938ZM9.26057 16.9021H9.26569H9.26057Z"
          fill="#091E42"
        />
        <path
          d="M16.083 10.4602C15.9852 10.4602 15.8875 10.423 15.8132 10.3483L13.6544 8.18992C13.5052 8.04075 13.5052 7.79908 13.6544 7.65001C13.8034 7.50094 14.0451 7.50094 14.1942 7.65001L16.3531 9.80898C16.5022 9.95805 16.5022 10.1997 16.3531 10.3488C16.2778 10.423 16.1801 10.4602 16.083 10.4602Z"
          fill="#091E42"
        />
        <path
          d="M9.25964 17.2836C9.16191 17.2836 9.06427 17.2465 8.99001 17.1716L6.83104 15.0128C6.68197 14.8636 6.68197 14.6219 6.83104 14.4729C6.98011 14.3238 7.22178 14.3238 7.37095 14.4729L9.52983 16.6318C9.67889 16.7809 9.67889 17.0226 9.52983 17.1716C9.45501 17.2465 9.35737 17.2836 9.25964 17.2836Z"
          fill="#091E42"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function ShareButton({ active = false, onClick = () => {}, ...rest }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={active ? classes.DislikeButtonActive : classes.DislikeButton}
      onClick={onClick}
      {...rest}
    >
      <rect width="36" height="36" rx="4" fill="#091E42" fillOpacity="0.04" />
      <g clipPath="url(#clip3)">
        <path
          d="M10.5312 25.3894C10.4625 25.3894 10.3938 25.3769 10.3281 25.3518C10.1125 25.2673 9.96875 25.0575 9.96875 24.8258V22.6685C9.96875 20.5362 10.7969 18.5291 12.3031 17.0199C13.8094 15.5107 15.8125 14.681 17.9406 14.681H18.6969V11.1741C18.6969 10.9424 18.8406 10.7327 19.0562 10.6481C19.2719 10.5636 19.5188 10.6231 19.675 10.7953L25.8813 17.6149C26.0781 17.8309 26.0781 18.1597 25.8813 18.3757L19.6781 25.1953C19.5219 25.3675 19.275 25.4239 19.0594 25.3424C18.8438 25.2579 18.7 25.0481 18.7 24.8164V21.3127C15.975 21.3659 13.375 22.5432 11.5344 24.5628L10.9469 25.2078C10.8375 25.3237 10.6875 25.3894 10.5312 25.3894ZM17.9406 15.8082C16.1125 15.8082 14.3906 16.5221 13.1 17.8184C11.8062 19.1115 11.0938 20.8368 11.0938 22.6685V23.3918C13.1594 21.344 15.9688 20.1824 18.8875 20.1824H19.2625C19.5719 20.1824 19.825 20.436 19.825 20.746V23.3605L24.7094 17.9937L19.825 12.6301V15.2446C19.825 15.5546 19.5719 15.8082 19.2625 15.8082H17.9406Z"
          fill="#091E42"
          fillOpacity="0.32"
        />
      </g>
      <defs>
        <clipPath id="clip3">
          <rect width="16" height="16" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function SendButton({ active = false, onClick = () => {}, ...rest }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={active ? classes.DislikeButtonActive : classes.DislikeButton}
      onClick={onClick}
      {...rest}
    >
      <rect width="36" height="36" rx="4" fill="#091E42" fillOpacity="0.04" />
      <g clipPath="url(#clip3)">
        <path
          fill="#091E42"
          fillOpacity="0.32"
          d="M64.192,108.444h359.617c29.943,0,54.223-24.278,54.223-54.222C478.031,24.278,453.754,0,423.809,0H64.192
			C34.248,0,9.969,24.278,9.969,54.222C9.969,84.166,34.248,108.444,64.192,108.444z"
        />
      </g>
      <defs>
        <clipPath id="clip3">
          <rect width="16" height="16" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function DownloadButton({ active = false, onClick = () => {}, ...rest }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 470.801 470.801"
      style={{ borderRadius: "10%" }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={active ? classes.DislikeButtonActive : classes.DislikeButton}
      onClick={onClick}
      {...rest}
    >
      <rect
        width="550.801px"
        height="550.801px"
        rx="4"
        fill="#091E42"
        fillOpacity="0.4"
      />
      <g transform="translate(130,130) scale(0.4)">
        <path
          width="30"
          height="30"
          d="M160.381,282.225c0-14.832-10.299-23.684-28.474-23.684c-7.414,0-12.437,0.715-15.071,1.432V307.6
      c3.114,0.707,6.942,0.949,12.192,0.949C148.419,308.549,160.381,298.74,160.381,282.225z"
        />
        <path
          width="30"
          height="30"
          d="M272.875,259.019c-8.145,0-13.397,0.717-16.519,1.435v105.523c3.116,0.729,8.142,0.729,12.69,0.729
      c33.017,0.231,54.554-17.946,54.554-56.474C323.842,276.719,304.215,259.019,272.875,259.019z"
        />
        <path
          width="30"
          height="30"
          d="M488.426,197.019H475.2v-63.816c0-0.398-0.063-0.799-0.116-1.202c-0.021-2.534-0.827-5.023-2.562-6.995L366.325,3.694
      c-0.032-0.031-0.063-0.042-0.085-0.076c-0.633-0.707-1.371-1.295-2.151-1.804c-0.231-0.155-0.464-0.285-0.706-0.419
      c-0.676-0.369-1.393-0.675-2.131-0.896c-0.2-0.056-0.38-0.138-0.58-0.19C359.87,0.119,359.037,0,358.193,0H97.2
      c-11.918,0-21.6,9.693-21.6,21.601v175.413H62.377c-17.049,0-30.873,13.818-30.873,30.873v160.545
      c0,17.043,13.824,30.87,30.873,30.87h13.224V529.2c0,11.907,9.682,21.601,21.6,21.601h356.4c11.907,0,21.6-9.693,21.6-21.601
      V419.302h13.226c17.044,0,30.871-13.827,30.871-30.87v-160.54C519.297,210.838,505.47,197.019,488.426,197.019z M97.2,21.605
      h250.193v110.513c0,5.967,4.841,10.8,10.8,10.8h95.407v54.108H97.2V21.605z M362.359,309.023c0,30.876-11.243,52.165-26.82,65.333
      c-16.971,14.117-42.82,20.814-74.396,20.814c-18.9,0-32.297-1.197-41.401-2.389V234.365c13.399-2.149,30.878-3.346,49.304-3.346
      c30.612,0,50.478,5.508,66.039,17.226C351.828,260.69,362.359,280.547,362.359,309.023z M80.7,393.499V234.365
      c11.241-1.904,27.042-3.346,49.296-3.346c22.491,0,38.527,4.308,49.291,12.928c10.292,8.131,17.215,21.534,17.215,37.328
      c0,15.799-5.25,29.198-14.829,38.285c-12.442,11.728-30.865,16.996-52.407,16.996c-4.778,0-9.1-0.243-12.435-0.723v57.67H80.7
      V393.499z M453.601,523.353H97.2V419.302h356.4V523.353z M484.898,262.127h-61.989v36.851h57.913v29.674h-57.913v64.848h-36.593
      V232.216h98.582V262.127z"
        />
      </g>
      <defs>
        <clipPath id="clip3">
          <rect width="16" height="16" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function FavouriteButton({ active = false, onClick = () => {}, ...rest }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={active ? classes.FavouriteButtonActive : classes.FavouriteButton}
      {...rest}
    >
      <rect width="36" height="36" rx="4" fill="#091E42" fillOpacity="0.04" />
      <g clipPath="url(#clip1)">
        <path
          d="M25.9292 16.0737C25.7443 15.5091 25.2458 15.1162 24.6548 15.0733L20.8017 14.7926L19.3424 11.2169C19.1178 10.6688 18.5929 10.3156 17.9986 10.3156C17.4043 10.3156 16.8793 10.6688 16.6548 11.2202L15.1987 14.7992L11.3457 15.0799C10.7547 15.1228 10.2528 15.5157 10.0712 16.0803C9.88964 16.6449 10.0613 17.2557 10.517 17.6387L13.4687 20.1315L12.5442 23.8822C12.4352 24.318 12.531 24.7703 12.805 25.1203C13.0824 25.4736 13.5116 25.6849 13.954 25.6849C14.2214 25.6849 14.4856 25.609 14.72 25.4637L18.0019 23.4265L21.2838 25.4637C21.5182 25.609 21.7823 25.6849 22.0497 25.6849C22.4922 25.6849 22.9214 25.4736 23.1987 25.1203C23.4728 24.7703 23.5685 24.318 23.4596 23.8822L22.5351 20.1315L25.4868 17.6387C25.9358 17.2491 26.1108 16.6383 25.9292 16.0737ZM24.7538 16.767L21.5314 19.4843C21.3663 19.623 21.2937 19.8442 21.3465 20.0522L22.3535 24.1463C22.3799 24.2487 22.3601 24.3378 22.3007 24.4137C22.2016 24.5392 22.0233 24.5821 21.8781 24.493L18.2957 22.271C18.2033 22.2148 18.1009 22.1851 17.9986 22.1851C17.8962 22.1851 17.7906 22.2148 17.7014 22.271L14.1191 24.4963C13.9738 24.5854 13.7955 24.5458 13.6965 24.417C13.6371 24.3411 13.6172 24.2487 13.6437 24.1496L14.6507 20.0555C14.7035 19.8475 14.6309 19.6263 14.4658 19.4876L11.2433 16.7703C11.0948 16.6449 11.1245 16.4897 11.1443 16.427C11.1641 16.3642 11.2301 16.2223 11.4249 16.2091L15.628 15.902C15.8426 15.8855 16.0308 15.7501 16.11 15.552L17.7014 11.6494C17.7741 11.4711 17.9325 11.4513 17.9953 11.4513C18.058 11.4513 18.2165 11.4711 18.2891 11.6494L19.8805 15.552C19.9631 15.7501 20.148 15.8888 20.3626 15.902L24.5656 16.2091C24.7571 16.2223 24.8265 16.3675 24.8463 16.427C24.8694 16.4864 24.9024 16.6416 24.7538 16.767Z"
          fill="#091E42"
          fillOpacity="0.32"
        />
      </g>
      <defs>
        <clipPath id="clip1">
          <rect width="16" height="16" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </svg>
  )
}

const DefaultMediaIcons = {
  website: website,
  twitter: twitter,
  linkedin: linkedin,
  facebook: facebook,
  crunchbase: crunchbase,
  google: google,
  founder: founder,
}

export function MediaButton({ icon }) {
  const iconUrl = DefaultMediaIcons[icon.name]
  const link = icon.url

  return link ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={link}
      className={classes.MediaButton}
      style={{ width: "16px", height: "16px" }}
      target="_blank"
    >
      {iconUrl ? (
        <SVGIcon src={iconUrl} style={{ maxWidth: "9.5px", maxHeight: "10px" }} />
      ) : (
        ""
      )}
    </a>
  ) : null
}

const StatusButtonsLinks = {
  Active: ActiveIcon,
  Dead: DeadIcon,
  Merged: MergedIcon,
}

export const StatusButtons = ({ status }) => {
  const link = StatusButtonsLinks[status]

  return <SVGIcon style={{ width: "16px", height: "16px" }} src={link} />
}
