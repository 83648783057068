import {
  TOGGLE_WELCOME,
  SET_ACTIVE_PAGE,
  LOG_IN,
  TOGGLE_STARTUP_ONEPAGER,
  SET_APPLICATION_STATE,
  SET_SIDEBAR_SETTINGS,
  SET_NB_STARTUPS_LIST,
  LOG_OUT,
} from "./types"
import appStates from "./appStates"

export const toggleWelcome = () => ({
  type: TOGGLE_WELCOME,
})

export const setActivePage = (tab) => ({
  type: SET_ACTIVE_PAGE,
  payload: tab,
})

export const logIn = (userInfo) => ({
  type: LOG_IN,
  payload: userInfo,
})

export const setNbStartups = (nbStartups) => ({
  type: SET_NB_STARTUPS_LIST,
  payload: nbStartups,
})

export const toggleStartupOnepager = (startup = null) => ({
  type: TOGGLE_STARTUP_ONEPAGER,
  payload: startup,
})

export const setApplicationState = (state = appStates.initial) => ({
  type: SET_APPLICATION_STATE,
  payload: state,
})

export const setSidebarSettings = (settings) => ({
  type: SET_SIDEBAR_SETTINGS,
  payload: settings,
})

export const signOut = () => ({
  type: LOG_OUT,
})
