import React from "react"
import classes from "./Select.module.scss"

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Checkbox from "@material-ui/core/Checkbox"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

const checkedIcon = <CheckBoxIcon fontSize="small" />
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />

const Popper = ({ children }) => <div className={classes.Popper}>{children}</div>

const Paper = ({ children }) => <div className={classes.Paper}>{children}</div>

export default function SelectInput({
  options = [],
  label = "",
  onChange,
  value = [],
  multiple = true,
  ...rest
}) {
  return (
    <Autocomplete
      multiple={multiple}
      disableCloseOnSelect
      options={options}
      onChange={onChange}
      value={value}
      {...rest}
      renderTags={() => null}
      PopperComponent={Popper}
      PaperComponent={Paper}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          className={classes.SmallerPadding}
          variant="outlined"
          placeholder="Select or start typing"
        />
      )}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            color="primary"
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </React.Fragment>
      )}
    />
  )
}
