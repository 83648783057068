import React, { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import { editWatching } from "src/redux/startups/actions"
import CompanyCard from "./Card"
import classes from "./CompanyList.module.scss"
import ShareModal from "./ShareModal"
import CircularProgress from "@material-ui/core/CircularProgress"
import { CSVLink } from "react-csv"
import { ScoutingApi } from "src/api"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"

// eslint-disable-next-line react/display-name
export const List = React.memo(({ filteredStartupList }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [startup, setStartup] = useState(null)
  const handleClose = () => {
    setOpen(false)
  }

  const onCloseTransitionEnd = () => {
    setStartup(null)
  }

  const openModal = useCallback((startupId) => {
    setStartup(startupId)
    setOpen(true)
  }, [])

  useEffect(() => {
    if (
      startup &&
      !filteredStartupList.some((company) => company.id === startup.id)
    ) {
      handleClose()
    }
  }, [filteredStartupList])
  dispatch(editWatching(""))

  const statusCompanies = [
    "Shortlist",
    "Shortlist (reserve)",
    "To verify",
    "Excluded",
  ]
  return (
    <div className={classes.CompanyList} data-test-id="startups-list">
      {statusCompanies.map((statusCompany) => {
        const filteredStartupListRresult = filteredStartupList.filter(
          (word) => word.status === statusCompany
        )

        return (
          <React.Fragment key={statusCompany}>
            <div className={classes.Divider}>
              <span className={classes.DividerSpan}>{statusCompany}</span>
            </div>
            {filteredStartupListRresult.map((startup) => (
              <CompanyCard
                key={startup.id}
                onShareClick={openModal}
                data={startup}
                displayMoreButton={true}
              />
            ))}
          </React.Fragment>
        )
      })}
      <ShareModal
        open={open}
        startup={startup}
        handleClose={handleClose}
        onCloseEnd={onCloseTransitionEnd}
      />
    </div>
  )
})
// eslint-disable-next-line react/display-name
const Placeholder = React.memo(({ EmptyPlaceholder }) => {
  const dispatch = useDispatch()
  dispatch(editWatching(""))
  return EmptyPlaceholder ? <EmptyPlaceholder /> : "No data"
})

export default function CompanyList({ list, EmptyPlaceholder }) {
  // eslint-disable-next-line no-unused-vars
  const [scoutingList, SetScoutingList] = useState(list)
  const specificListID = useSelector((state) => state.startups.specificListID)
  //const offset = page * PER_PAGE

  useEffect(async () => {
    const resultStartups = await ScoutingApi.getSpecificScoutingStatus(
      specificListID
    )
    await SetScoutingList(resultStartups.json.startups)
  })

  const waitSearch = useSelector((state) => state.startups.waitSearch)

  //  const today = new Date()
  //  const dd = String(today.getDate()).padStart(2, "0")
  // const mm = String(today.getMonth() + 1).padStart(2, "0")
  //const yyyy = today.getFullYear()
  //const today = mm + "/" + dd + "/" + yyyy
  return (
    <div className={classes.Container}>
      <div style={{ textAlign: "right" }}>
        <CSVLink
          data={scoutingList}
          //(date of download)_astorya.io_(name of the list)
          // filename={`${mm + "/" + dd + "/" + yyyy}_astorya.io_.csv`}
          asyncOnClick={true}
          onClick={async (event, done) => {
            const resultStartups = await ScoutingApi.getSpecificScoutingStatus(
              specificListID
            )
            await SetScoutingList(resultStartups.json.startups)
            done(true)
          }}
        >
          <CloudDownloadIcon color="disabled" fontSize="large" />
        </CSVLink>
      </div>
      {waitSearch === "initial" ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : null}
      <div className={classes.Header}>
        <p>Company name</p>
        <p>Country</p>
        <p>Est.</p>
        <p>Tags</p>
        <p>Fundraising</p>
        <p>Employees</p>
        <p>Links</p>
        <p>Status</p>
        <p></p>
        <p>Shortlist</p>
        <span></span>
      </div>
      {list.length ? (
        <List filteredStartupList={list} />
      ) : (
        <Placeholder EmptyPlaceholder={EmptyPlaceholder} />
      )}
    </div>
  )
}

/* */
