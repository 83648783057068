import React from "react"
import clsx from "clsx"
import classes from "./Tag.module.scss"

export const palette = {
  blue: {
    primary: "#103DF5",
    outlined: "rgba(16, 61, 245, 0.1)",
  },
  purple: {
    primary: "#5910F5",
    outlined: "rgba(89, 16, 245, 0.1)",
  },
  pink: {
    primary: "#E310F5",
    outlined: "rgba(227, 16, 245, 0.1)",
  },
  green: {
    primary: "#57B585",
    outlined: "rgba(87, 181, 133, 0.1)",
  },
  red: {
    primary: "#F51047",
    outlined: "#FEE7ED",
  },
  orange: {
    primary: "#F57E10",
    outlined: "rgba(245, 126, 16, 0.1)",
  },
  default: {
    primary: "#091E42",
    outlined: "rgba(9, 30, 66, 0.1)",
  },
}

export default function TagComponent({
  children,
  parent = false,
  active,
  color,
  onClick,
  //block = false,
  ...rest
}) {
  function getClassName() {
    let className = clsx(
      classes.Tag,
      onClick && classes.ButtonTag,
      parent && classes.Parent
    )
    return className
  }

  const colors = palette[color] || palette.default

  return (
    <span
      {...rest}
      {...(onClick && { onClick: onClick })}
      className={getClassName(active)}
      style={{
        backgroundColor: active ? colors.primary : colors.outlined,
        color: active ? "#FFFFFF" : colors.primary,
      }}
    >
      {children}
    </span>
  )
}
