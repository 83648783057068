import React, { useState } from "react"
import classes from "./SignIn.module.scss"
import { Formik, Field, Form } from "formik"
import CircularProgress from "@material-ui/core/CircularProgress"

import { Typography, Button, Link, TextField } from "@material-ui/core"
import { formTypes, formStatus } from "./index"

function ForgotPasswordForm({ changeFormType }) {
  // eslint-disable-next-line no-unused-vars
  const [formState, setFormState] = useState(formStatus.initial)
  function onFinish() {
    // TODO Handle password request
  }

  return (
    <div style={{ width: "430px" }}>
      <div className={classes.CardWrapper}>
        <Typography variant="h2" style={{ marginBottom: "0px" }}>
          Forgot password
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "0px" }}>
          Please enter your email to get reset link.
        </Typography>
        <Formik
          onSubmit={onFinish}
          initialValues={{ email: "" }}
          //validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
        >
          {({ handleSubmit, handleChange, errors }) => (
            <Form className={classes.FormWrapper}>
              <Field
                type="email"
                onChange={handleChange}
                component={TextField}
                className={classes.SmallerPadding}
                id="email"
                name="email"
                placeholder="Email"
                variant="outlined"
                error={!!errors.email}
                label={errors.email}
                fullWidth
              />

              <Button
                fullWidth={true}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disableElevation
              >
                {formState !== formStatus.waitingForResponse ? (
                  "Send me the link"
                ) : (
                  <CircularProgress
                    size="1rem"
                    color="inherit"
                    style={{ color: "white", margin: "4px" }}
                  />
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.Extras}>
        <Link onClick={() => changeFormType(formTypes.signIn)} color="primary">
          Back to login
        </Link>
        <div className={classes.ButtonGroupWrapper}>
          <span>{"Don’t have an account? "}</span>
          <Link
            color="primary"
            onClick={() => changeFormType(formTypes.requestAccess)}
          >
            Request access
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordForm
