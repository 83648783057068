import React, { useMemo, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Tag } from "src/components/common"
import Link from "@material-ui/core/Link"
import { FavouritesApi } from "src/api"
import classes from "./Card.module.scss"
import Select from "@material-ui/core/Select"
//import InputLabel from "@material-ui/core/InputLabel"

//import { Select } from "src/components/common"
import {
  toggleFavourite,
  toggleRequestedStartup,
  editWatching,
  setCategory,
  setSimilarStartups,
} from "src/redux/startups/actions"
import MenuItem from "@material-ui/core/MenuItem"
import {
  ShareButton,
  DownloadButton,
  FavouriteButton,
} from "src/components/common/IconButtons"
import { Button, Typography, FormControl } from "@material-ui/core"
import { StatusButtons } from "src/components/common/IconButtons"
import { RequestInfoApi, SimilarStartupApi } from "src/api"
import { useSnackbar } from "notistack"

import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"

import MediaButtons from "./MediaButtons"
import Rating from "react-rating"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import { differenceInMonths } from "date-fns"
import ReactToPrint from "react-to-print"
import logo from "src/assets/images/rocketLogo.png"
import { ScoutingApi } from "src/api"
import { setSpecificListScoutings } from "../../../../../redux/startups/actions"

//import Moment from "react-moment"

const SmallBadge = () => (
  <span
    style={{
      color: "#b8bec8",
      fontSize: "8px",
      backgroundColor: "#f5f6f8",
      borderRadius: "24px",
      padding: "1px 6px 2px 6px",
      fontWeight: 700,
      marginLeft: "4px",
    }}
  >
    exclu
  </span>
)

const Status = ({ type }) => {
  switch (type) {
    default:
      return <p>-</p>
    case "FAUX":
      return (
        <p style={{ color: "#77caa3" }}>
          <StatusButtons status={"Active"} />
          Active
        </p>
      )
    case "ACQUIRED":
      return (
        <p style={{ color: "#091E42" }}>
          <StatusButtons status={"Merged"} />
          Merged
        </p>
      )
    case "VRAI":
      return (
        <p style={{ color: "#F51047" }}>
          <StatusButtons status={"Dead"} />
          Dead
        </p>
      )
  }
}
const Column = ({ children, style }) => (
  <div
    style={{
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      ...style,
    }}
  >
    {children}
  </div>
)

const topPourcentage = (input) => {
  if (input >= 99) {
    return (
      <React.Fragment>
        <div
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {" "}
          {">> Top 1%"}
        </div>
        <div>&nbsp; &nbsp; &nbsp; Top 10%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 25%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 50%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 100%</div>
      </React.Fragment>
    )
  } else if (input >= 90 && input < 99) {
    return (
      <React.Fragment>
        <div>&nbsp; &nbsp; &nbsp; Top 1%</div>
        <div
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {" "}
          {">> Top 10%"}
        </div>
        <div>&nbsp; &nbsp; &nbsp; Top 25%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 50%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 100%</div>
      </React.Fragment>
    )
  } else if (input >= 75 && input < 90) {
    return (
      <React.Fragment>
        <div>&nbsp; &nbsp; &nbsp; Top 1%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 10%</div>
        <div
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {">> Top 25%"}
        </div>
        <div>&nbsp; &nbsp; &nbsp; Top 50%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 100%</div>
      </React.Fragment>
    )
  } else if (input >= 50 && input < 75) {
    return (
      <React.Fragment>
        <div>&nbsp; &nbsp; &nbsp; Top 1%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 10%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 25%</div>
        <div
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {">> Top 50%"}
        </div>
        <div>&nbsp; &nbsp; &nbsp; Top 100%</div>
      </React.Fragment>
    )
  } else if (input < 50) {
    return (
      <React.Fragment>
        <div>&nbsp; &nbsp; &nbsp; Top 1%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 10%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 25%</div>
        <div>&nbsp; &nbsp; &nbsp; Top 50%</div>
        <div
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {">> Top 100%"}
        </div>
      </React.Fragment>
    )
  }
}

const DisplayNameRankingStartups = ({ name, classement }) => {
  return (
    classement && (
      <div>
        <Typography
          variant="h6"
          style={{
            marginTop: "16px",
            textDecoration: "underline",
            fontSize: "0.8rem",
            marginRight: "36px",
          }}
        >
          {name} ranking
        </Typography>
      </div>
    )
  )
}

const DisplayRankingStartups = ({ classement }) => {
  return (
    classement && (
      <div>
        <Typography
          variant="h6"
          style={{
            color: "black",
            marginRight: "72px",
          }}
        >
          {topPourcentage(classement)}
        </Typography>
      </div>
    )
  )
}

//const daysOfBeingNew = 157
//const daysinMS = daysOfBeingNew * 24 * 60 * 60 * 1000

function extractTags(tags) {
  return tags && tags.length !== 0 && tags.substring(1, tags.length - 1).split(",")
}

const requestStates = {
  initial: "initial",
  requesting: "requesting",
  requested: "requested",
}

function getButtonLabel(state) {
  switch (state) {
    case requestStates.initial:
      return "Request info"
    case requestStates.requesting:
      return "Requesting"
    case requestStates.requested:
      "Requested"
      break
    default:
      return ""
  }
}

const useRequestInfo = () => {
  const dispatch = useDispatch()
  const [requestState, setRequestState] = useState(requestStates.initial)
  const { enqueueSnackbar } = useSnackbar()

  async function sendRequest(data) {
    setRequestState(requestStates.requesting)
    try {
      await RequestInfoApi.addRequestInfos(data.id)
      dispatch(toggleRequestedStartup(data))
    } catch (e) {
      enqueueSnackbar("We couldn't send your request. Please try again later", {
        variant: "error",
      })
      setRequestState(requestStates.initial)
    }
  }

  return { requestState, sendRequest }
}

// eslint-disable-next-line react/display-name
export default React.memo(
  ({ data, displayMoreButton = false, onShareClick = () => {} }) => {
    const favourites = useSelector((state) => state.startups.favourites)
    const specificListID = useSelector((state) => state.startups.specificListID)
    const requestedStartups = useSelector((state) => state.startups.requestInfos)
    const similarStartups = useSelector((state) => state.startups.similarStartups)
    const tagsAPI = useSelector((state) => state.startups.tagsCollections)
    const { requestState, sendRequest } = useRequestInfo()
    const textInput = useRef(null)
    //const [scoutingValue, setScoutingValue] = useState([])

    const [statusScouting, setStatusScouting] = React.useState("")
    const [openCard, setOpenCard] = React.useState(false)

    const handleCloseCard = () => {
      setOpenCard(false)
    }

    const handleOpenCard = () => {
      setOpenCard(true)
    }

    async function onSendClick(event) {
      try {
        await setStatusScouting(event.target.value)
        await ScoutingApi.putSpecificScouting(
          Number(specificListID),
          event.target.value,
          data.id
        )
        const resultStartups = await ScoutingApi.getSpecificScouting(
          Number(specificListID)
        )
        await dispatch(setSpecificListScoutings(resultStartups))
        //showSuccess()
        //await setScoutingValue(newInputValue)
        //const resultStartups = await ScoutingApi.getSpecificScouting(newInputValue)
        //await dispatch(setSpecificListScoutings(resultStartups))
        //showSuccess()
      } catch (err) {
        //showError()
      }
    }

    const getTagColor = (tag) => {
      const mainTags = [
        "InsurTech",
        "FinTech",
        "DigitalHealth",
        "CyberSecurity",
        "Mobility",
        "SmartHome",
      ]
      const getTag =
        tagsAPI && tagsAPI.tags && tagsAPI.tags.find((item) => item.name === tag)
      return getTag ? (mainTags.includes(tag) ? "blue" : getTag.color) : ""
    }

    const {
      id,
      nom,
      pays = "-",
      created,
      currency,
      desription,
      total_fundraising,
      nb_zero,
      linkedin,
      passed,
      nb_employees,
      tag_array,
      one_pager,
      crunch_base,
      opinion_execution,
      opinion_execution_stars,
      opinion_positionning,
      opinion_positionning_stars,
      opinion_team,
      opinion_team_stars,
      opinion_team_stars_date,
      date_of_last_round,
      classement_insurtech,
      classement_fintech,
      classement_digitalhealth,
      classement_cybersecurity,
      classement_smartcity,
      classement_mobility,
      other_website,
    } = data

    const dispatch = useDispatch()
    // function isNew(createdAt) {
    //   const date = new Date(createdAt).valueOf()
    //   const currentTime = Date.now()
    //   return currentTime - daysinMS < date
    // }

    function isFavorite(id) {
      return favourites && favourites.some((item) => item.id === id)
    }

    function getButtonState(id) {
      if (
        requestedStartups &&
        requestedStartups.some((item) =>
          item.id === id ? item.id === id : item.follower_id === id
        )
      ) {
        return {
          disabled: true,
          label: "Requested",
        }
      }

      const buttonState = requestState === requestStates.requesting
      return {
        disabled: buttonState,
        label: getButtonLabel(requestState),
      }
    }

    function onToggleFavourite() {
      isFavorite(data.id)
        ? FavouritesApi.deleteFavourite(data.id)
        : FavouritesApi.addFavourite(data.id)
      dispatch(toggleFavourite(data))
    }

    function onSelectTag(tag) {
      if (
        localStorage.getItem("setTokenRoleFrontFilters") === "true" &&
        localStorage.getItem("setRoleUser") === ""
      ) {
        dispatch(editWatching("initial"))
        dispatch(setCategory(tag))
      }
    }

    /* 
    const print = () => {
      const string = renderToString(<PdfcardStartup data={data} tagsAPI={tagsAPI} />)
      const pdf = new jsPDF("p", "mm", "a4")
      pdf.fromHTML(string)
      pdf.save("pdf")
    } */

    function onToggleRequested() {
      sendRequest(data)
    }

    function getPourcentageOfLastRoud() {
      const numberOfDayDifferent = differenceInMonths(
        new Date(),
        new Date(date_of_last_round)
      )
      const numberForPourcentage =
        numberOfDayDifferent < 12
          ? numberOfDayDifferent
          : (numberOfDayDifferent - 12) * 2 + 12
      //numberOfDayDifferent > 540 ? numberOfDayDifferent / 3 :
      //numberOfDayDifferent > 365 && numberOfDayDifferent < 540
      //? numberOfDayDifferent - 180
      //: numberOfDayDifferent / 2
      const pourcentageDateOfLastRound = (numberForPourcentage / 12) * 100
      const resultPourcentage =
        pourcentageDateOfLastRound >= 300 ? 290 : pourcentageDateOfLastRound
      return resultPourcentage
    }

    const extractedTags = useMemo(() => extractTags(tag_array), [tag_array])
    const extractedSimilarStartups = useMemo(() => extractTags(similarStartups), [
      similarStartups,
    ])

    /*     const extractedSimilarStartups = useMemo(
      () =>
        extractTags(
          similar_array && similar_array !== [] && similar_array.similar_array
        ),
      [similar_array]
    ) */

    const { label, disabled } = getButtonState(id)
    const [open, setOpen] = useState(false)

    const handleOpen = async () => {
      try {
        const result = await SimilarStartupApi.getSimilarStartups(id)
        await dispatch(
          setSimilarStartups(result.startups[0] && result.startups[0].similar_array)
        )
      } catch (e) {
        console.log(e)
      }
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

    const colorWeight = ["blue", "purple", "red", "pink", "green", "orange"]
    const internetLogo =
      typeof other_website === "string"
        ? other_website && other_website.split("w.") && other_website.split("w.")[1]
          ? "https://logo.clearbit.com/" + other_website.split("w.")[1]
          : "https://logo.clearbit.com/" + other_website.split("//")[1]
        : ""

    const [imgError, setImgError] = React.useState(false)

    return (
      <React.Fragment>
        <section className={classes.Card}>
          <div className={classes.Data}>
            {!imgError ? (
              <img
                style={{ width: "30px", paddingRight: "10px" }}
                src={internetLogo}
                onError={() => setImgError(true)}
              />
            ) : (
              <img style={{ width: "30px", paddingRight: "10px" }} src={logo} />
            )}

            <Typography variant="h4" style={{ textOverflow: "...new" }}>
              <Link href={linkedin} color="inherit" target="_blank">
                {nom}
              </Link>
            </Typography>
            {!crunch_base && <SmallBadge />}
          </div>
          <div className={classes.Country}>{pays}</div>
          <div className={classes.CreationDate}>{created}</div>
          <div className={classes.TagWrapper}>
            {extractedTags &&
              extractedTags.length > 0 &&
              extractedTags
                .sort()
                .sort(function (a, b) {
                  if (a && b) {
                    if (
                      colorWeight.findIndex((item) => item === getTagColor(a)) <
                      colorWeight.findIndex((item) => item === getTagColor(b))
                    )
                      return -1
                    if (
                      colorWeight.findIndex((item) => item === getTagColor(a)) >
                      colorWeight.findIndex((item) => item === getTagColor(b))
                    )
                      return 1
                    return 0
                  } else {
                    return -1
                  }
                })
                .map((tag, index) =>
                  // TODO : Insert color assigned to category
                  tagsAPI &&
                  tagsAPI.tags &&
                  tagsAPI.tags.find((item) => item.name === tag) ? (
                    <Tag
                      key={`${id}_${tag}_${index}`}
                      color={getTagColor(tag)}
                      onClick={() => onSelectTag(tag)}
                    >
                      {tag}
                    </Tag>
                  ) : (
                    ""
                  )
                )}
          </div>

          <div
            className={classes.Fundraising}
            ref={textInput}
            style={{ width: "50%" }}
          >
            {currency}
            {total_fundraising}
            {nb_zero}
          </div>
          <div className={classes.Employees}>{nb_employees}</div>
          <MediaButtons data={data} />

          <div className={classes.Status} style={{ width: "50%" }}>
            <Status type={passed} />
          </div>

          <div className={classes.Buttons}>
            {displayMoreButton &&
              (opinion_execution ||
              opinion_execution_stars ||
              opinion_positionning ||
              opinion_positionning_stars ||
              opinion_team ||
              opinion_team_stars ||
              opinion_team_stars_date ? (
                <Column style={{ width: "100%" }}>
                  <Button
                    data-test-id="request-button"
                    variant="contained"
                    type="text"
                    className={classes.MoreButton}
                    fullWidth
                    onClick={handleOpen}
                    target="_blank"
                  >
                    astorya.io analysis
                  </Button>
                </Column>
              ) : (
                <Column style={{ width: "60%" }}>
                  <Button
                    data-test-id="request-button"
                    variant="contained"
                    type="text"
                    className={classes.MoreButton}
                    fullWidth
                    onClick={handleOpen}
                    target="_blank"
                  >
                    Show Info
                  </Button>
                </Column>
              ))}
          </div>
          <div className={classes.Shortlist}>
            <Column>
              <FormControl>
                <Select
                  open={openCard}
                  onClose={handleCloseCard}
                  variant="outlined"
                  className={classes.MoreButton}
                  onOpen={handleOpenCard}
                  placeholder="Action"
                  value={statusScouting || "Status"}
                  // || data.status
                  onChange={onSendClick}
                >
                  <MenuItem value={"Shortlist"}>Shortlist</MenuItem>
                  <MenuItem value={"Shortlist (reserve)"}>
                    Shortlist (reserve)
                  </MenuItem>
                  <MenuItem value={"To verify"}>To verify</MenuItem>
                  <MenuItem value={"Excluded"}>Excluded</MenuItem>
                </Select>
              </FormControl>
            </Column>
          </div>
        </section>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.Modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.Paper} ref={textInput}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <div className={classes.Data}>
                    <Typography variant="h4" style={{ textOverflow: "...new" }}>
                      <Link href={linkedin} color="inherit" target="_blank">
                        {nom}
                      </Link>
                    </Typography>
                    {!crunch_base && <SmallBadge />}
                  </div>
                  <div className={classes.Status}>
                    <Status type={passed} />
                  </div>
                </div>
                <div className={classes.Buttons}>
                  <Column>
                    <ShareButton
                      onClick={() => onShareClick(data)}
                      data-test-id="share-button"
                    />
                  </Column>
                  <Column>
                    <FavouriteButton
                      data-test-id="favorite-button"
                      active={isFavorite(id)}
                      onClick={onToggleFavourite}
                    />
                  </Column>
                  <Column>
                    <ReactToPrint
                      content={() => textInput.current}
                      trigger={() => <DownloadButton />}
                    />
                  </Column>
                </div>
              </div>
              <Typography
                variant="h6"
                style={{
                  marginTop: "16px",
                  textDecoration: "underline",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Info
              </Typography>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="h6"
                    style={{
                      paddingBottom: "6px",
                      paddingLeft: "30px",
                    }}
                  >
                    Country:
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      paddingBottom: "6px",
                      paddingRight: "50px",
                      paddingLeft: "10px",
                      color: "black",
                    }}
                  >
                    {pays}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography variant="h6"> Tags:{"  "}</Typography>
                  <Typography
                    variant="h6"
                    style={{ paddingLeft: "10px" }}
                    className={classes.TagWrapper}
                  >
                    {extractedTags &&
                      extractedTags.length > 0 &&
                      extractedTags
                        .sort()
                        .sort(function (a, b) {
                          if (a && b) {
                            if (
                              colorWeight.findIndex(
                                (item) => item === getTagColor(a)
                              ) <
                              colorWeight.findIndex(
                                (item) => item === getTagColor(b)
                              )
                            )
                              return -1
                            if (
                              colorWeight.findIndex(
                                (item) => item === getTagColor(a)
                              ) >
                              colorWeight.findIndex(
                                (item) => item === getTagColor(b)
                              )
                            )
                              return 1
                            return 0
                          } else {
                            return -1
                          }
                        })
                        .map((tag, index) =>
                          // TODO : Insert color assigned to category
                          tagsAPI &&
                          tagsAPI.tags &&
                          tagsAPI.tags.find((item) => item.name === tag) ? (
                            <Tag
                              style={{
                                paddingBottom: "6px",
                                borderLeft: "10px",
                              }}
                              key={`${id}_${tag}_${index}`}
                              color={getTagColor(tag)}
                              onClick={() => onSelectTag(tag)}
                            >
                              {tag}
                            </Tag>
                          ) : (
                            ""
                          )
                        )}
                  </Typography>
                </div>
              </div>
              <div>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "16px",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    fontSize: "1rem",
                  }}
                >
                  Description
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    paddingLeft: "30px",
                    fontSize: "0.8rem",
                    color: "black",
                    paddingBottom: "6px",
                  }}
                >
                  {desription}
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "16px",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    fontSize: "1rem",
                    width: "33%",
                  }}
                >
                  Maturity
                </Typography>

                <DisplayNameRankingStartups
                  name="Insurtech"
                  classement={classement_insurtech}
                />
                <DisplayNameRankingStartups
                  name="Fintech"
                  classement={classement_fintech}
                />
                <DisplayNameRankingStartups
                  name="DigitalHealth"
                  classement={classement_digitalhealth}
                />
                <DisplayNameRankingStartups
                  name="SmartHome"
                  classement={classement_smartcity}
                />
                <DisplayNameRankingStartups
                  name="Mobility"
                  classement={classement_mobility}
                />
                <DisplayNameRankingStartups
                  name="Cybersecurity"
                  classement={classement_cybersecurity}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "33%" }}>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      style={{ paddingLeft: "30px", width: "60%" }}
                    >
                      Date of creation:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: "black", paddingLeft: "10px" }}
                    >
                      {created}
                    </Typography>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      style={{ paddingLeft: "30px", width: "60%" }}
                    >
                      Size of the team:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: "black", paddingLeft: "10px" }}
                    >
                      {nb_employees}
                    </Typography>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      style={{
                        paddingLeft: "30px",
                        paddingBottom: "6px",
                        width: "60%",
                      }}
                    >
                      Fundraising:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "10px",
                        paddingBottom: "6px",
                      }}
                    >
                      {currency}
                      {total_fundraising}
                      {nb_zero}
                    </Typography>
                  </div>
                </div>
                <DisplayRankingStartups
                  name="Insurtech"
                  classement={classement_insurtech}
                />
                <DisplayRankingStartups
                  name="Fintech"
                  classement={classement_fintech}
                />
                <DisplayRankingStartups
                  name="DigitalHealth"
                  classement={classement_digitalhealth}
                />

                <DisplayRankingStartups
                  name="SmartHome"
                  classement={classement_smartcity}
                />
                <DisplayRankingStartups
                  name="Mobility"
                  classement={classement_mobility}
                />
                <DisplayRankingStartups
                  name="Cybersecurity"
                  classement={classement_cybersecurity}
                />
              </div>
              {date_of_last_round && total_fundraising && total_fundraising !== "-" && (
                <React.Fragment>
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: "16px",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      fontSize: "1rem",
                      marginBottom: "3px",
                    }}
                  >
                    Next deal opportunity
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      fontSize: "0.8rem",
                    }}
                  >
                    <Typography
                      style={{
                        height: "25px",
                        display: "block",
                        fontSize: "0.8rem",
                        color: "black",
                        width: "33%",
                        backgroundColor: "#00cc97",
                        textAlign: "center",
                        marginBottom: "10px",
                        marginTop: "5px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          height: "25px",
                          width: "25px",
                          fontSize: "0.8rem",
                          color: "black",
                          backgroundColor: "blue",
                          border: "1px solid black",
                          borderRadius: "50%",
                          position: "absolute",
                          left: `${getPourcentageOfLastRoud()}%`,
                          zIndex: 2,
                        }}
                      ></div>
                      Get in touch!
                    </Typography>
                    <Typography
                      style={{
                        height: "25px",
                        display: "block",
                        width: "33%",
                        fontSize: "0.8rem",
                        color: "black",
                        backgroundColor: "#EFCF04",
                        textAlign: "center",
                        marginBottom: "10px",
                        marginTop: "5px",
                        position: "relative",
                      }}
                    >
                      {"Don't miss the deal!"}
                    </Typography>
                    <Typography
                      style={{
                        height: "25px",
                        display: "block",
                        width: "33%",
                        fontSize: "0.8rem",
                        color: "black",
                        backgroundColor: "#d45d5d",
                        textAlign: "center",
                        marginBottom: "10px",
                        marginTop: "5px",
                        position: "relative",
                      }}
                    >
                      Can you still make the deal?
                    </Typography>
                  </div>
                </React.Fragment>
              )}
              {(opinion_execution ||
                opinion_execution_stars > 0 ||
                opinion_positionning ||
                opinion_positionning_stars > 0 ||
                opinion_team ||
                opinion_team_stars > 0 ||
                opinion_team_stars_date) && (
                <React.Fragment>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      style={{
                        marginTop: "16px",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        fontSize: "1rem",
                        marginBottom: "3px",
                      }}
                    >
                      astorya.io analysis
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        marginTop: "18px",
                        paddingLeft: "5px",
                        fontWeight: "bold",
                        fontSize: "0.5rem",
                      }}
                    >
                      ( {opinion_team_stars_date} )
                    </Typography>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      style={{ paddingLeft: "30px", width: "15.5%" }}
                    >
                      Founding team:
                    </Typography>
                    <div>
                      <Typography
                        variant="h6"
                        style={{ color: "black", paddingLeft: "10px" }}
                      >
                        <Rating
                          initialRating={opinion_team_stars || 0}
                          readonly
                          emptySymbol={
                            <StarBorderIcon style={{ fontSize: "0.9em" }} />
                          }
                          fullSymbol={<StarIcon style={{ fontSize: "0.9em" }} />}
                        />
                      </Typography>

                      <div style={{ display: "flex" }}>
                        <Typography
                          variant="h6"
                          style={{ color: "black", paddingLeft: "10px" }}
                        >
                          {opinion_team}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      style={{ paddingLeft: "30px", width: "15.5%" }}
                    >
                      Market positioning:
                    </Typography>
                    <div>
                      <Typography
                        variant="h6"
                        style={{ color: "black", paddingLeft: "10px" }}
                      >
                        <Rating
                          initialRating={opinion_positionning_stars || 0}
                          readonly
                          emptySymbol={
                            <StarBorderIcon style={{ fontSize: "0.9em" }} />
                          }
                          fullSymbol={<StarIcon style={{ fontSize: "0.9em" }} />}
                        />
                      </Typography>

                      <div style={{ display: "flex" }}>
                        <Typography
                          variant="h6"
                          style={{ color: "black", paddingLeft: "10px" }}
                        >
                          {opinion_positionning}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      style={{ paddingLeft: "30px", width: "15.5%" }}
                    >
                      To-date execution:
                    </Typography>
                    <div>
                      <Typography
                        variant="h6"
                        style={{ color: "black", paddingLeft: "10px" }}
                      >
                        <Rating
                          initialRating={opinion_execution_stars || 0}
                          readonly
                          emptySymbol={
                            <StarBorderIcon style={{ fontSize: "0.9em" }} />
                          }
                          fullSymbol={<StarIcon style={{ fontSize: "0.9em" }} />}
                        />
                      </Typography>

                      <div style={{ display: "flex" }}>
                        <Typography
                          variant="h6"
                          style={{ color: "black", paddingLeft: "10px" }}
                        >
                          {opinion_execution}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {extractedSimilarStartups && extractedSimilarStartups.length > 0 && (
                <div>
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: "16px",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      fontSize: "1rem",
                    }}
                  >
                    Similar Startups
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      paddingLeft: "30px",
                      display: "flex",
                      fontSize: "0.8rem",
                      color: "black",
                      paddingBottom: "6px",
                    }}
                    className={classes.TagWrapper}
                  >
                    {extractedSimilarStartups &&
                      extractedSimilarStartups.length > 0 &&
                      extractedSimilarStartups
                        .slice(1, 6)
                        .sort()
                        .map((tag, index) => (
                          // TODO : Insert color assigned to category
                          <Tag
                            style={{
                              paddingBottom: "6px",
                              borderLeft: "10px",
                            }}
                            key={`${id}_${tag}_${index}`}
                            color={getTagColor(tag)}
                          >
                            {tag}
                          </Tag>
                        ))}
                  </Typography>
                </div>
              )}
              <div>
                {displayMoreButton && (
                  <Column style={{ width: "100%" }}>
                    {one_pager ? (
                      <Button
                        data-test-id="request-button"
                        variant="contained"
                        type="text"
                        className={classes.MoreButton}
                        color="primary"
                        fullWidth
                        href={one_pager}
                        onClick={onToggleRequested}
                        target="_blank"
                      >
                        Display deeper analysis
                      </Button>
                    ) : (
                      <Button
                        data-test-id="request-button"
                        variant="contained"
                        type="text"
                        className={classes.MoreButton}
                        disabled={disabled}
                        onClick={onToggleRequested}
                        fullWidth
                      >
                        {label}
                      </Button>
                    )}
                  </Column>
                )}
              </div>
            </div>
          </Fade>
        </Modal>
      </React.Fragment>
    )
  }
)
