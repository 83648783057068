import React from "react"
import Typography from "@material-ui/core/Typography"
import classes from "./Title.module.scss"

function TitleComponent(props) {
  return (
    <Typography className={classes.Title} variant="h1">
      {props.children}
    </Typography>
  )
}

export default TitleComponent
