export const getSavedSearches = () => async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/mentionusers`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    }
  )
  const data = await response.json()
  return data
}

export const updateSavedSearches = async (id, name) => {
  await fetch(`${process.env.REACT_APP_API_URL}/api/v1/mentionusers`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    },
    body: JSON.stringify({
      id: id,
      name: name,
    }),
  })
  return true
}
