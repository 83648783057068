import React, { useState } from "react"
import classes from "./SignIn.module.scss"
import { Formik, Field, Form } from "formik"
import CircularProgress from "@material-ui/core/CircularProgress"

import { Typography, Button, Link, TextField } from "@material-ui/core"
import { formTypes, formStatus } from "./index"

function RequestAccessForm({ changeFormType }) {
  // eslint-disable-next-line no-unused-vars
  const [formState, setFormState] = useState(formStatus.initial)
  function onFinish() {
    // TODO Handle access request
  }

  return (
    <div style={{ width: "430px" }}>
      <div className={classes.CardWrapper}>
        <Typography variant="h2" style={{ marginBottom: "0px" }}>
          Request access
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "0px" }}>
          Please leave your email, we will write back.
        </Typography>
        <Formik
          onSubmit={onFinish}
          initialValues={{ email: "" }}
          //validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
        >
          {({ handleSubmit, handleChange, errors }) => (
            <Form className={classes.FormWrapper}>
              <Field
                type="email"
                onChange={handleChange}
                component={TextField}
                className={classes.SmallerPadding}
                id="email"
                name="email"
                placeholder="Email"
                variant="outlined"
                error={!!errors.email}
                label={errors.email}
                fullWidth
              />

              <Button
                fullWidth={true}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disableElevation
              >
                {formState !== formStatus.waitingForResponse ? (
                  "Send me the link"
                ) : (
                  <CircularProgress
                    size="1rem"
                    color="inherit"
                    style={{ color: "white", margin: "4px" }}
                  />
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.Extras}>
        <span style={{ width: "100%", textAlign: "center" }}>
          Already have an account?{" "}
          <Link
            rel="noopener"
            color="primary"
            onClick={() => changeFormType(formTypes.signIn)}
            style={{ paddingLeft: "0px" }}
          >
            Sign in here
          </Link>
        </span>
      </div>
    </div>
  )
}
export default RequestAccessForm
