import React from "react"
import classes from "./Card.module.scss"
import { MediaButton } from "src/components/common/IconButtons"

function MediaButtons({ data }) {
  return (
    <div className={classes.Links}>
      <MediaButton icon={{ name: "website", url: data.other_website }} />
      <MediaButton icon={{ name: "twitter", url: data.site_web }} />
      <MediaButton icon={{ name: "linkedin", url: data.linkedin }} />
      <MediaButton icon={{ name: "crunchbase", url: data.crunch_base }} />
      <MediaButton icon={{ name: "facebook", url: data.facebook }} />
      <MediaButton icon={{ name: "google", url: data.google }} />
      <MediaButton icon={{ name: "founder", url: data.founder }} />
    </div>
  )
}

export default MediaButtons
