import React, { useState } from "react"

import SignInForm from "./SignInForm"
import ForgotPasswordForm from "./ForgotPasswordForm"
import RequestAccessForm from "./RequestAccessForm"

export const formStatus = {
  initial: "initial",
  waitingForResponse: "waitingForResponse",
  failed: "failed",
}

export const formTypes = {
  signIn: "SignIn",
  requestAccess: "RequestAccess",
  forgotPassword: "ForgotPassword",
}

const Renderers = {
  [formTypes.signIn]: SignInForm,
  [formTypes.requestAccess]: RequestAccessForm,
  [formTypes.forgotPassword]: ForgotPasswordForm,
}

function SignInPanel() {
  const [currentForm, changeFormType] = useState(formTypes.signIn)
  const Content = Renderers[currentForm]

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 100px)",
      }}
    >
      {Content ? <Content changeFormType={changeFormType} /> : <h1>Error</h1>}
    </div>
  )
}

export default SignInPanel
