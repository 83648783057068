//@ts-check
import {
  SET_ACTIVE_PAGE,
  TOGGLE_WELCOME,
  LOG_IN,
  TOGGLE_STARTUP_ONEPAGER,
  SET_APPLICATION_STATE,
  SET_SIDEBAR_SETTINGS,
  SET_NB_STARTUPS_LIST,
  LOG_OUT,
} from "./types"
import { initState } from "../initState"
import appStates from "./appStates"
const { view: viewInitState } = initState

export default function reducer(state = viewInitState, action) {
  const { type, payload } = action

  const cases = {
    [SET_ACTIVE_PAGE]: () => {
      return {
        ...state,
        activeTab: payload,
      }
    },
    [SET_NB_STARTUPS_LIST]: () => {
      return {
        ...state,
        nbStartups: payload,
      }
    },
    [TOGGLE_WELCOME]: () => {
      return {
        ...state,
        welcomePanelActive: !state.welcomePanelActive,
      }
    },
    [LOG_IN]: () => {
      const {
        email = "",
        avatarUrl = null,
        userType = "Empty",
        mainTag = "InsurTech",
      } = payload
      return {
        ...state,
        loggedIn: true,
        appState: appStates.loading,
        user: {
          ...state.user,
          email,
          avatarUrl,
          userType,
          mainTag,
        },
      }
    },

    [TOGGLE_STARTUP_ONEPAGER]: () => {
      return {
        ...state,
        startupInModal: payload,
      }
    },

    [SET_APPLICATION_STATE]: () => {
      return {
        ...state,
        appState: payload,
      }
    },

    [SET_SIDEBAR_SETTINGS]: () => {
      return {
        ...state,
        sidebarSettings: { ...payload },
      }
    },

    [LOG_OUT]: () => {
      return {
        ...state,
        loggedIn: false,
        appState: appStates.initial,
        user: {},
      }
    },
  }

  return cases[type] ? cases[type]() : state
}
