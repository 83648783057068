import React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"

// eslint-disable-next-line react/display-name
export default ({ children }) => (
  <HelmetProvider>
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://use.typekit.net/iyv5hls.css"
      />
    </Helmet>
    {children}
  </HelmetProvider>
)
