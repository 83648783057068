import React, { useState } from "react"
import { Button, Typography, Modal, FormControl } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
//import { Select } from "src/components/common"
//import { saveSearchCriteria } from "src/redux/startups/actions"
import classes from "./Sidebar.module.scss"
import { useSnackbar } from "notistack"
import { ScoutingApi } from "src/api"
import {
  setSpecificListScoutings,
  setSpecificListScoutingsId,
  setUserSpecificListScoutings,
} from "../../../../redux/startups/actions"
import { Select } from "src/components/common"
import "@fontsource/roboto"

//import MenuItem from "@material-ui/core/MenuItem"

import Radio from "@material-ui/core/Radio"
import { makeStyles } from "@material-ui/core/styles"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "432px",
    outline: "none",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    padding: "16px",
  },
}))

const formStatus = {
  initial: "initial",
  sending: "sending",
  error: "error",
}

export default function Sidebar() {
  //const [modalVisible, toggleModal] = useState(false)

  //const inputRef = useRef()

  // function saveToDashboard() {
  // dispatch(saveSearchCriteria(inputRef.current.input.value))
  // toggleModal(false)
  // }
  const modalClasses = useStyles()

  const dispatch = useDispatch()
  const [statusValidation, setStatusValidation] = useState(formStatus.initial)
  const scouting = useSelector((state) => state.startups.scouting)
  const scoutingUser = useSelector(
    (state) =>
      state.startups.userSpecificScouting &&
      state.startups.userSpecificScouting.json &&
      state.startups.userSpecificScouting.json.scouting
  )
  const [value, setValue] = useState(0)
  const [open, setOpen] = useState(false)

  const [userTeam, setUserTeam] = useState([])

  const [seledtedUserInvitation, setSeledtedUserInvitation] = useState([])

  const options = userTeam &&
    userTeam.json && [...userTeam.json.map(({ email }) => email)]

  function onCountryChange(event, newInputValue) {
    setSeledtedUserInvitation(newInputValue)
  }

  //const filteredStartupList = useSelector((state) => {
  //  return state.startups.filteredStartupList
  //})

  const { enqueueSnackbar } = useSnackbar()

  function showSuccess() {
    enqueueSnackbar("User Invitation sent.", {
      variant: "success",
      autoHideDuration: 1000,
    })
  }

  function showError() {
    enqueueSnackbar("We couldn't share this startup, please try again later.", {
      variant: "error",
      autoHideDuration: 1000,
    })
  }

  const handleOpen = async () => {
    const resultUserTeams = await ScoutingApi.getUserTeams(value)
    setUserTeam(resultUserTeams)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  async function handleChange(event) {
    try {
      await setValue(Number(event.target.value))
      await dispatch(setSpecificListScoutingsId(event.target.value))
      const resultStartups = await ScoutingApi.getSpecificScouting(
        event.target.value
      )
      await dispatch(setSpecificListScoutings(resultStartups))

      const resultUsers = await ScoutingApi.getUserListScouting(event.target.value)
      await dispatch(setUserSpecificListScoutings(resultUsers))

      // showSuccess()
    } catch (err) {
      showError()
    }
  }

  async function onSendClick() {
    // const inputValue = emailRef.current.value

    try {
      setStatusValidation(formStatus.sending)

      await ScoutingApi.postUserInvitationScouting(seledtedUserInvitation, value)
      //await dispatch(setSpecificListScoutings(resultStartups))
      setStatusValidation(formStatus.initial)
      showSuccess()
      handleClose()
    } catch (err) {
      setStatusValidation(formStatus.error)
      showError()
    }
  }

  const buttonsDisabled = statusValidation !== formStatus.initial
  return (
    <div className={classes.Container}>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <Typography variant="h5"> Select your List </Typography>
      </div>
      <section className={classes.Section}>
        <Typography variant="h6">List</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="scouting"
            name="scouting"
            value={value}
            onChange={handleChange}
          >
            {scouting.map(({ name, list_scouting_id }) => (
              <FormControlLabel
                key={list_scouting_id}
                value={list_scouting_id}
                control={<Radio />}
                label={name}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </section>

      {value !== 0 ? (
        <section className={classes.Section}>
          <Typography variant="h6">
            User who can access this scouting list
          </Typography>
          {scoutingUser &&
            scoutingUser.map((user) => (
              <Typography
                key={user.id}
                style={{
                  color: "black",
                  marginTop: "8px",
                  fontWeight: "300",
                  marginLeft: "30px",
                  fontSize: "0.9rem",
                  marginBottom: "3px",
                }}
              >
                {" "}
                {user.email}{" "}
              </Typography>
            ))}
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            style={{ width: "100%", marginTop: "30px" }}
          >
            Invite users to scounting list
          </Button>
        </section>
      ) : (
        ""
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={modalClasses.modal}
        open={open}
        BackdropComponent={Backdrop}
        disableBackdropClick
        BackdropProps={{
          timeout: 350,
        }}
      >
        <Fade in={open} onExited={handleClose}>
          <div className={modalClasses.paper} data-test-id="share-modal-content">
            <Typography variant="h4">Invite user from your team</Typography>
            <div
              style={{
                marginTop: "20px",
                minHeight: "100px",
                display: "grid",
                gridRowGap: "14px",
                gridTemplateRows: "max-content",
                alignItems: "flex-end",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ marginTop: "10px" }}
                fullWidth
              >
                <Select
                  multiple={true}
                  onChange={onCountryChange}
                  options={options}
                  value={seledtedUserInvitation}
                />
              </FormControl>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridColumnGap: "8px",
                  width: "100%",
                  marginTop: "18px",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                  fullWidth
                  disabled={buttonsDisabled}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSendClick}
                  disabled={buttonsDisabled}
                  fullWidth
                >
                  {buttonsDisabled ? "Connecting" : "Connect"}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
