import { createStore, applyMiddleware, compose } from "redux"
import rootReducer from "./rootReducer"
import rootSaga from "./sagas/rootSaga"
import createSagaMiddleware from "redux-saga"

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(sagaMiddleware),
    (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) ||
      compose
  )
)

sagaMiddleware.run(rootSaga)

export default store
