export const startupShare = () => async (id, email) => {
  await fetch(`${process.env.REACT_APP_API_URL}/api/v1/shared`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    },
    body: JSON.stringify({
      follower_id: id,
      email: email,
    }),
  })
  return true
}
