import React, { useState } from "react"
import { formStatus } from "./index"

import { useDispatch } from "react-redux"

import { LoginApi } from "src/api"
import { Formik, Field, Form } from "formik"

import { logIn } from "src/redux/actions"
import CircularProgress from "@material-ui/core/CircularProgress"

import classes from "./SignIn.module.scss"
import {
  Typography,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { setActivePage } from "src/redux/actions"
import { viewPages } from "src/redux/view/viewPages"

import * as Yup from "yup"

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Password required"),
  email: Yup.string().email("Invalid email").required("Email required"),
  keepLoggedIn: Yup.boolean(),
})

function setKeepLogging({ keepLoggedIn, email, password }) {
  if (!keepLoggedIn) {
    window.localStorage.removeItem("email")
    window.localStorage.removeItem("password")
    window.localStorage.setItem("keepLoggedIn", false)
  }
  if (keepLoggedIn) {
    window.localStorage.setItem("email", email)
    window.localStorage.setItem("password", password)
    window.localStorage.setItem("keepLoggedIn", keepLoggedIn)
  }
}
function SignInForm() {
  //{ changeFormType = () => {} }
  const dispatch = useDispatch()
  const [formState, setFormState] = useState(formStatus.initial)

  async function onFinish(props) {
    //setFormState(formStatus.waitingForResponse);
    const loginPromise = LoginApi.signIn(props)

    try {
      const response = await loginPromise()

      setKeepLogging(props)
      dispatch(logIn(response))
      await dispatch(setActivePage(viewPages.SEARCH))
    } catch {
      setFormState(formStatus.failed)
    }
    // if (response) {
    // } else
  }

  return (
    <div style={{ width: "430px" }} data-test-id="sign-in-form">
      <div className={classes.CardWrapper}>
        <Typography variant="h2" style={{ marginBottom: "0px" }}>
          Sign in
        </Typography>
        <Formik
          onSubmit={onFinish}
          initialValues={{ email: "", password: "", keepLoggedIn: true }}
          validationSchema={SignupSchema}
          //validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
        >
          {({ handleSubmit, handleChange, values, errors }) => {
            function handleKeyDown(e) {
              if (e.key === "Enter") {
                handleSubmit()
              }
            }
            return (
              <Form className={classes.FormWrapper}>
                <Field
                  type="email"
                  onChange={handleChange}
                  component={TextField}
                  className={classes.SmallerPadding}
                  id="email"
                  name="email"
                  placeholder="Email"
                  variant="outlined"
                  error={!!errors.email}
                  onKeyDown={handleKeyDown}
                  label={errors.email}
                  fullWidth
                />
                <Field
                  type="password"
                  id="password"
                  className={classes.SmallerPadding}
                  onChange={handleChange}
                  component={TextField}
                  name="password"
                  placeholder="Password"
                  variant="outlined"
                  onKeyDown={handleKeyDown}
                  error={!!errors.password}
                  label={errors.password}
                  fullWidth
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="keepLoggedIn"
                      name="keepLoggedIn"
                      checked={values.keepLoggedIn}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Keep logged in"
                ></FormControlLabel>
                {formState === formStatus.failed && (
                  <Alert severity="warning">Email or password is incorrect.</Alert>
                )}
                <Button
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disableElevation
                >
                  {formState !== formStatus.waitingForResponse ? (
                    "Sign in"
                  ) : (
                    <CircularProgress
                      size="1rem"
                      color="inherit"
                      style={{ color: "white", margin: "4px" }}
                    />
                  )}
                </Button>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default SignInForm

// <div className={classes.Extras}>
// <Link
//   onClick={() => changeFormType(formTypes.forgotPassword)}
//   color="primary"
// >
//   Forgot password?
// </Link>
// <div className={classes.ButtonGroupWrapper}>
//   <span>{"Don’t have an account? "}</span>
//   <Link
//     color="primary"
//     onClick={() => changeFormType(formTypes.requestAccess)}
//   >
//     Request access
//   </Link>
// </div>
// </div>
