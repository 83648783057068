import React, { useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import { useSnackbar } from "notistack"
import { FormControl } from "@material-ui/core"
import { Select } from "src/components/common"
import { ScoutingApi } from "src/api"
import { setSpecificListScoutings } from "../../../redux/startups/actions"

//import { ShareStartupApi } from "src/api"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "432px",
    outline: "none",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    padding: "16px",
  },
}))

const formStatus = {
  initial: "initial",
  sending: "sending",
  error: "error",
}

//startup
function ShareModalList({ open, handleClose, onCloseEnd }) {
  const dispatch = useDispatch()
  const scouting = useSelector((state) => state.startups.scouting)
  const [status, setStatus] = useState(formStatus.initial)
  const modalClasses = useStyles()
  const [scoutingValue, setScoutingValue] = useState([])

  const { enqueueSnackbar } = useSnackbar()

  function showSuccess() {
    enqueueSnackbar("Startups in your list is saved successfully.", {
      variant: "success",
      autoHideDuration: 1000,
    })
  }

  function showError() {
    enqueueSnackbar("We couldn't share this startup, please try again later.", {
      variant: "error",
      autoHideDuration: 1000,
    })
  }

  function onScountingChange(event, newInputValue) {
    setScoutingValue(newInputValue)
  }

  async function onSendClick() {
    try {
      setStatus(formStatus.sending)
      const resultStartups = await ScoutingApi.getSpecificScouting(scoutingValue)
      await dispatch(setSpecificListScoutings(resultStartups))
      setStatus(formStatus.initial)
      showSuccess()
      handleClose()
    } catch (err) {
      setStatus(formStatus.error)
      showError()
    }
  }
  //const inputRef = useRef()
  const buttonsDisabled = status !== formStatus.initial
  const options = scouting && [...scouting.map(({ name }) => name)]

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={modalClasses.modal}
      open={open}
      BackdropComponent={Backdrop}
      disableBackdropClick
      BackdropProps={{
        timeout: 350,
      }}
    >
      <Fade in={open} onExited={onCloseEnd}>
        <div className={modalClasses.paper} data-test-id="share-modal-content">
          <Typography variant="h4">Choose your list</Typography>
          <div
            style={{
              marginTop: "20px",
              minHeight: "100px",
              display: "grid",
              gridRowGap: "14px",
              gridTemplateRows: "max-content",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="body2">Choose from:</Typography>

            <FormControl variant="outlined" style={{ marginTop: "10px" }} fullWidth>
              <Select
                multiple={false}
                value={scoutingValue}
                onChange={onScountingChange}
                options={options}
              />
            </FormControl>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridColumnGap: "8px",
                width: "100%",
                marginTop: "18px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                fullWidth
                disabled={buttonsDisabled}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onSendClick}
                disabled={buttonsDisabled}
                fullWidth
              >
                {buttonsDisabled ? "Connecting" : "Connect"}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default ShareModalList
