import { createSelector } from "reselect"

export const startupStoreSelector = (state) => state.startups

export const viewStoreSelector = (state) => state.view

export const getActiveTab = (state) => state.view.activeTab

// export const getFavourites = (state) =>
//   state.startups.favourites.reduce((prev, curr) => {
//     const alreadyInCollection = prev.some((item) => item.id === curr);

//     if (!alreadyInCollection) {
//       const row = state.startups.startupList.find(
//         (startup) => startup.id === curr
//       );
//       if (row) return prev.concat(row);
//     }

//     return prev;
//   }, []);

export const getFavourites = createSelector(
  startupStoreSelector,
  (startups) => startups.favourites
)

export const setSimilarStartups = createSelector(
  startupStoreSelector,
  (startups) => startups.similarStartups
)

export const getSavedSearches = (state) => state.startups.savedSearches

export const getFilters = createSelector(
  startupStoreSelector,
  (startups) => startups.filters
)

export const getDescriptionFromFilters = (state) => getFilters(state).search

export const isLoggedIn = (state) => state.view.loggedIn

export const startupInModal = (state) => state.view.startupInModal

export const getStartupInfo = (id, state) =>
  id ? state.startups.startupList.find((item) => item.id === id) : null

export const getApplicationState = createSelector(viewStoreSelector, (view) => ({
  isLoggedIn: view.loggedIn,
  appState: view.appState,
  welcomePanelActive: view.welcomePanelActive,
  activePage: view.activeTab,
}))

export const getSidebarSettings = (state) => state.view.sidebarSettings

// TODO refactor XD
export const getAllTagCollections = createSelector(
  getSidebarSettings,
  ({ mainTags, customerSegment, technology }) =>
    mainTags && customerSegment && technology
      ? [
          ...mainTags
            .map((main) => [
              { parent: main.parent, value: main.parent },
              ...main.subTags
                .map((coll, index) => [
                  coll.map((item) => ({
                    value: item,
                    parent: main.parent,
                    color: index === 0 ? "purple" : "pink",
                  })),
                ])
                .flat(2),
            ])
            .flat(),
          ...customerSegment.map((tag) => ({
            parent: "CustomerSegment",
            color: "green",
            value: tag,
          })),
          ...technology.map((tag) => ({
            parent: "Technology",
            color: "orange",
            value: tag,
          })),
        ]
      : []
)
