import React from "react"
import Navbar from "../Navbar"
import classes from "./Layout.module.scss"

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <Navbar />
      <main className={classes.Main}>{children}</main>
    </React.Fragment>
  )
}
