export const setToken = (idToken) => {
  localStorage.setItem("id_token", idToken)
}
export const setTokenRoleFrontFilters = (user) => {
  localStorage.setItem("setTokenRoleFrontFilters", user)
}
export const setTokenRoleFrontSavedFavories = (user) => {
  localStorage.setItem("setTokenRoleFrontSavedFavories", user)
}
export const setTokenRoleSoucing = (user) => {
  localStorage.setItem("setTokenRoleSoucing", user)
}
export const setTokenRoleFrontSavedSearch = (user) => {
  localStorage.setItem("setTokenRoleFrontSavedSearch", user)
}
export const setTokenRoleFrontSearch = (user) => {
  localStorage.setItem("setTokenRoleFrontSearch", user)
}
export const setTokenRoleFrontList = (user) => {
  localStorage.setItem("setTokenRoleFrontList", user)
}
export const setRoleUser = (role) => {
  localStorage.setItem("setRoleUser", role)
}
