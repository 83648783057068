import React from "react"
import Kpi from "./LiveData"

function Dashboard() {
  return (
    <div
      style={{ margin: "auto", maxWidth: "1200px" }}
      data-test-id="dashboard-wrapper"
    >
      <Kpi />
    </div>
  )
}

export default Dashboard
