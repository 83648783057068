export const getUserListScouting = async (list_id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/userlistscoutings?list_id=` + list_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    }
  )
  const data = await response.json()
  return data
}

export const getUserTeams = async (list_id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/userteams?list_id=` + list_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    }
  )
  const data = await response.json()
  return data
}

export const getListScouting = () => async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/listscoutings`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    }
  )
  const data = await response.json()

  return data
}

export const putSpecificScouting = async (id, status, follower_id) => {
  // eslint-disable-next-line no-extra-boolean-cast
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/listscoutingfollowers`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
      body: JSON.stringify({
        list_id: id,
        status: status,
        follower_id: follower_id,
      }),
    }
  )
  const data = await response.json()
  return data
}

export const postSpecificScouting = async (list_id, followers) => {
  // eslint-disable-next-line no-extra-boolean-cast
  await fetch(`${process.env.REACT_APP_API_URL}/api/v1/listscoutings/getId`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    },
    body: JSON.stringify({
      list_id: list_id,
      followers: JSON.stringify(followers),
    }),
  })

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/listscoutings`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    }
  )
  const data = await response.json()
  return data
}

export const postUserInvitationScouting = async (users, list_id) => {
  // eslint-disable-next-line no-extra-boolean-cast
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/userteaminvitations`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
      body: JSON.stringify({
        users: JSON.stringify(users),
        list_id: list_id,
      }),
    }
  )
  const data = await response.json()
  return data
}

export const postSpecificScoutingWithoutInput = async (list_id, followers) => {
  // eslint-disable-next-line no-extra-boolean-cast
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/listscoutingfollowers/getId`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
      body: JSON.stringify({
        list_id: list_id,
        followers: JSON.stringify(followers),
      }),
    }
  )
  const data = await response.json()
  return data
}

export const getSpecificScouting = async (list_id) => {
  // eslint-disable-next-line no-extra-boolean-cast
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/listscoutingfollowers?list_id=` +
      list_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    }
  )
  const data = await response.json()
  return data
}

export const getSpecificScoutingStatus = async (list_id) => {
  // eslint-disable-next-line no-extra-boolean-cast
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/listscoutingfollowersstatus?list_id=` +
      list_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    }
  )
  const data = await response.json()
  return data
}
