//import { useSelector } from "react-redux"
//import { startupInModal } from "src/redux/selectors"

function StartupModal() {
  //const startup = useSelector(startupInModal)

  return (
    // <Modal bodyStyle={{ padding: 0 }} width={"80%"} open={!!startup}>
    //   {startup ? <Content data={startup} /> : null}
    // </Modal>
    null
  )
}

export default StartupModal
