// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Slider_SliderContainer__1hrH5 {\n  margin-top: 10px; }\n  .Slider_SliderContainer__1hrH5 > .Slider_ant-slider-track__3thQR {\n    height: 6px !important;\n    background: #091e42 !important; }\n\n.Slider_Container__2_6WV {\n  margin: 15px 0; }\n\n.Slider_Track__7ANnP {\n  color: red; }\n", "",{"version":3,"sources":["webpack://src/components/common/Slider/Slider.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA;EADlB;IAGI,sBAAsB;IACtB,8BAA8B,EAAA;;AAIlC;EACE,cAAc,EAAA;;AAGhB;EACE,UAAU,EAAA","sourcesContent":[".SliderContainer {\n  margin-top: 10px;\n  > .ant-slider-track {\n    height: 6px !important;\n    background: #091e42 !important;\n  }\n}\n\n.Container {\n  margin: 15px 0;\n}\n\n.Track {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SliderContainer": "Slider_SliderContainer__1hrH5",
	"ant-slider-track": "Slider_ant-slider-track__3thQR",
	"Container": "Slider_Container__2_6WV",
	"Track": "Slider_Track__7ANnP"
};
export default ___CSS_LOADER_EXPORT___;
