import React from "react"
import classes from "./Kpi.module.scss"
import { Typography } from "@material-ui/core"
import Title from "src/components/Typography/Title"

export function Kpi() {
  return (
    <div>
      <Title> Live data on the European InsurTech scene </Title>
      <div className={classes.Wrapper}>
        <Typography style={{ paddingBottom: "5px" }} variant="h5">
          Live Data
        </Typography>

        <div className={classes.Container} style={{ textAlign: "center" }}>
          <img
            src="https://www.dropbox.com/s/dhk7bpihwa4rnjl/astorya.io_kpilive1.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "7px" }}
          />
          <img
            src="https://www.dropbox.com/s/qj450tpi27mdkg1/astorya.io_kpilive2.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "7px" }}
          />
          <img
            src="https://www.dropbox.com/s/o83z1vvy6sindtg/astorya.io_kpilive3.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "7px" }}
          />
        </div>
        <div className={classes.Container} style={{ textAlign: "center" }}>
          <img
            src="https://www.dropbox.com/s/z6q1491hmdlytyp/astorya.io_kpilive4.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "7px" }}
          />
          <img
            src="https://www.dropbox.com/s/ved1ow9rpus4wb8/astorya.io_kpilive5.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "7px" }}
          />
          <img
            src="https://www.dropbox.com/s/pck26yqctve9ocn/astorya.io_kpilive6.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "7px" }}
          />
        </div>
      </div>
      <div className={classes.Wrapper}>
        <Typography style={{ paddingBottom: "5px" }} variant="h5">
          Our yearly reviews: the state of InsurTech Europe
        </Typography>
        <div className={classes.Container} style={{ textAlign: "center" }}>
          <div style={{ fontSize: "1rem" }}>
            <a href="https://research.astorya.io/post/insurtech-europe-2023-full-year-in-review">
              2023
            </a>
            -
            <a href="https://research.astorya.io/post/insurtech-europe-2022-full-year-in-review">
              2022
            </a>
            -
            <a href="https://research.astorya.io/post/insurtech-europe-2021-full-year-in-review">
              2021
            </a>
            -
            <a href="https://research.astorya.io/post/insurtech-europe-2020-full-year-in-review">
              2020
            </a>
            -
            <a href="https://research.astorya.io/post/insurtech-europe-2019-full-year-in-review">
              2019
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
