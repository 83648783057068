export const search = (filters) => async () => {
  let search = window.location.search
  let params = new URLSearchParams(search)
  let tokenParams = params.get("tokenShow")

  // eslint-disable-next-line no-extra-boolean-cast
  if (!!tokenParams) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/nbstartups/uniq_startup?tokenShow=` +
        tokenParams,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        },
      }
    )

    const data = await response.json()
    return data
  } else {
    let query = Object.keys(filters)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(filters[k]))
      .join("&")

    let queryStatus = filters.status
      ? Object.keys(filters.status)
          .map(
            (k) =>
              encodeURIComponent(k) + "=" + encodeURIComponent(filters.status[k])
          )
          .join("&")
      : ""

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/followers?` +
        query +
        "&" +
        queryStatus,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        },
      }
    )
    const data = await response.json()
    return data
    //if (filters.load === "new") return data;
    //return filterStartups(data, filters);
  }
}

// function filterStartups(startupList, filters) {
//   const {
//     categories = [],
//     search = "",
//     createdRange = [2010, 2021],
//     country = [],
//     employeesRange = {
//       oneToTen: true,
//       elevenToFifthy: true,
//       fifthyOneToTwoHundred: true,
//       twoHundredOneToFiveHundred: true,
//     },
//     status = {
//       active: true,
//       merged: true,
//       dead: true,
//     },
//   } = filters
//   const filtered = startupList.reduce((all, current) => {
//     //Checking for all the filters

//     const includesDescription = current.pays.includes(search)
//       ? true
//       : current.nom.includes(search)
//       ? true
//       : current.created.includes(search)
//       ? true
//       : categories.some((item) =>
//           current.mentions.some((mention) => mention.name.includes(item))
//         )
//       ? true
//       : false

//     const hasEmployees = current.nb_employees === employeesRange

//     const created =
//       parseInt(current.created) >= createdRange[0] &&
//       parseInt(current.created) <= createdRange[1]

//     const hasCategory =
//       categories.length === 0
//         ? true
//         : categories.some((item) =>
//             current.mentions.some((mention) => mention.name.includes(item))
//           )

//     const includesCountry = country.length
//       ? country.some((test) =>
//           test.toLowerCase().includes(current.pays.toLowerCase())
//         )
//       : true

//     /*
//       const employeeArray = Object.keys(employeesRange).filter(
//         (key) => status[key] === true
//       );

//       const employeeIncluded =
//       employeeArray.length == 4 || statusArray.length === 0
//           ? true
//           : employeeArray.some(
//               (el) => el.toLowerCase() === current.status.toLowerCase() || false
//             ); */

//     const statusArray = Object.keys(status).filter((key) => status[key] === true)

//     const statusIncluded =
//       statusArray.length == 3 || statusArray.length === 0
//         ? true
//         : statusArray.some(
//             (el) => el.toLowerCase() === current.passed.toLowerCase() || false
//           )

//     if (
//       includesDescription &&
//       //hasEmployees &&
//       created &&
//       statusIncluded &&
//       hasCategory &&
//       includesCountry
//     ) {
//       all.push(current)
//     }

//     return all
//   }, [])

//   return filtered
// }
