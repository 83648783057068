import React, { useRef } from "react"
import Typography from "@material-ui/core/Typography"
import Slider from "@material-ui/core/Slider"
import classes from "./Slider.module.scss"
import { withStyles } from "@material-ui/core/styles"

const CustomSlider = withStyles({
  root: {
    color: "#3880ff",
    height: 2,
    padding: "15px 0",
    boxSizing: "border-box",
    marginLeft: "12px",
    marginRight: "12px",
    maxWidth: "calc(100% - 24px)",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#E6E9EC",
    marginTop: -9,
    marginLeft: -12,
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 8px)",
    top: -17,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 6,
  },
  rail: {
    height: 6,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
  },
})(Slider)

export default function SliderComponent({
  min,
  max,
  title,
  onChange = () => {},
  //tooltipVisible = true,
  defaultValue = null,
  marks = [],
  step = null,
  ...rest
}) {
  const test = useRef(null)
  const onCommitted = (event, val) => {
    onChange(val)
  }

  const defaults = useRef(defaultValue).current

  return (
    <div className={classes.Container}>
      <Typography variant="h6">{title}</Typography>

      <CustomSlider
        {...rest}
        min={min}
        max={max}
        innerRef={test}
        step={step}
        marks={marks}
        defaultValue={defaults ? defaults : [min, max]}
        className={classes.SliderContainer}
        onChangeCommitted={onCommitted}
        valueLabelDisplay={"on"}
      />
    </div>
  )
}
