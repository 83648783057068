// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tag_Tag__yY_BR {\n  font-size: 10px;\n  line-height: 1.4;\n  border-radius: 15px;\n  margin: 0 4px 4px 0;\n  padding: 2px 10px;\n  box-sizing: border-box;\n  outline: none;\n  transition: background-color 0.2s, color 0.2s; }\n\n.Tag_Parent__3_lSD {\n  font-weight: bolder; }\n\n.Tag_ButtonTag__Boqb7 {\n  cursor: pointer; }\n", "",{"version":3,"sources":["webpack://src/components/common/Tag/Tag.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,sBAAsB;EACtB,aAAa;EACb,6CAA6C,EAAA;;AAG/C;EACE,mBAAmB,EAAA;;AAGrB;EACE,eAAe,EAAA","sourcesContent":[".Tag {\n  font-size: 10px;\n  line-height: 1.4;\n  border-radius: 15px;\n  margin: 0 4px 4px 0;\n  padding: 2px 10px;\n  box-sizing: border-box;\n  outline: none;\n  transition: background-color 0.2s, color 0.2s;\n}\n\n.Parent {\n  font-weight: bolder;\n}\n\n.ButtonTag {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tag": "Tag_Tag__yY_BR",
	"Parent": "Tag_Parent__3_lSD",
	"ButtonTag": "Tag_ButtonTag__Boqb7"
};
export default ___CSS_LOADER_EXPORT___;
