import React, { useEffect } from "react"
import { Provider } from "react-redux"
import store from "./redux/store"
import { SnackbarProvider } from "notistack"
import { StylesProvider, ThemeProvider } from "@material-ui/styles"
import { theme } from "./styles/muiTheme"
import "./styles/index.scss"
import Helmet from "./Helmet"

function AppProvider({ children }) {
  useEffect(() => {
    store.dispatch({ type: "STARTUP" })
  }, [])
  return (
    <Helmet>
      <Provider store={store}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {children}
            </SnackbarProvider>
          </ThemeProvider>
        </StylesProvider>
      </Provider>
    </Helmet>
  )
}

if (window.Cypress) {
  window.store = store
}

export default AppProvider
