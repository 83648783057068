import React from "react"
//import Title from "src/components/Typography/Title"
//import FirstSection from "./FirstSection"
//import SavedSearches from "./SavedSearches"
//import { useSelector } from "react-redux"
//import Button from "@material-ui/core/Button"

function Dashboard() {
  //  const logInState = useSelector((state) => state.view.loggedIn)

  return (
    <div style={{ height: "100vh" }}>
      <iframe
        src="https://research.astorya.io/"
        style={{
          position: "fixed",
          top: "70",
          left: "0",
          bottom: "0",
          right: "0",
          width: "100%",
          height: "100%",
          border: "none",
          margin: "0",
          padding: "0",
          overflow: "hidden",
        }}
      ></iframe>
    </div>
  )
}

export default Dashboard
