import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
}))

function HighlightedText({ children }) {
  const highlight = useStyles()

  return <span className={highlight.root}>{children}</span>
}

export default HighlightedText
