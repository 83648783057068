export const EmployeesMarks = [
  { value: 1, label: "1", storeValue: 1 },
  { value: 2, label: "10", storeValue: 10 },
  { value: 3, label: "50", storeValue: 50 },
  { value: 4, label: "200", storeValue: 200 },
  { value: 5, label: "500", storeValue: 500 },
  { value: 6, label: "1000", storeValue: 1000 },
  { value: 7, label: "1000+", storeValue: Infinity },
]

export const FundraisingMarks = [
  { value: 0, label: "€0M", storeValue: 0 },
  { value: 3, label: "€3M", storeValue: 3 },
  { value: 5, label: "€5M", storeValue: 5 },
  { value: 10, label: "€10M", storeValue: 10 },
  { value: 50, label: "€50M", storeValue: 50 },
  { value: 100, label: "€100M+", storeValue: Infinity },
]

const currentYear = new Date().getFullYear()
const minYear = 2000

export const EstabilishedMarks = Array(currentYear - minYear + 1)
  .fill({})
  .map((el, index) => ({
    value: minYear + index,
    label: index !== 0 ? `${index + minYear}` : `<${minYear}`,
    storeValue: index !== 0 ? index + minYear : -Infinity,
  }))

export const getMarkLabel = (value, collection) =>
  collection.find((item) => item.value === value).label

export const getStoreValue = (value, collection) =>
  collection.find((item) => item.value === value).storeValue

export const getMinMax = (collection = []) =>
  collection.length && collection.length > 1
    ? [collection[0].value, collection[collection.length - 1].value]
    : [0, 0]

export const getValueFromStoreValue = (storeValue, collection) => {
  collection.length && collection.length > 1
    ? collection.find((item) => item.storeValue === storeValue).value
    : 1
}
