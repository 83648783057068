import { takeEvery, put, call } from "redux-saga/effects"
import { isLoggedIn } from "src/redux/selectors"

import {
  FavouritesApi,
  ScoutingApi,
  FiltersApi,
  LoginApi,
  SavedSearchesApi,
  SearchApi,
  RequestInfoApi,
  NbStartupsApi,
} from "src/api"
import appStates from "../view/appStates"
import { LOG_IN } from "../view/types"
import {
  setFavourites,
  setListScoutings,
  setSearchStartupsList,
  setSavedSearches,
  setNumberStartupsList,
  setRequest,
  setTags,
  setTagsCollection,
} from "../startups/actions"
import { setNbStartups } from "../view/actions"
import { initState } from "src/redux/initState"
import { logIn, setApplicationState, setSidebarSettings } from "../view/actions"

import { deserializeSidebarSettings } from "src/api/deserialize"

function* loadAppState() {
  try {
    if (isLoggedIn) {
      const listScoutings = yield call(ScoutingApi.getListScouting())
      if (
        listScoutings &&
        listScoutings.json &&
        listScoutings.json.scouting.length
      ) {
        yield put(setListScoutings(listScoutings.json.scouting))
      }

      const favourites = yield call(FavouritesApi.getFavourites({ page: 1 }))
      if (favourites && favourites.json && favourites.json.favories.length) {
        yield put(setFavourites(favourites.json.favories))
      }
      const requestInfos = yield call(RequestInfoApi.getRequestInfo())
      if (requestInfos && requestInfos.json && requestInfos.json.info.length) {
        yield put(setRequest(requestInfos.json.info))
      }

      const tags = yield call(RequestInfoApi.getRequestTag())
      if (tags && tags.json && tags.json.mainTags) {
        yield put(setTags(tags.json))
      }

      const tagsCollections = yield call(RequestInfoApi.getTagCollection())
      if (tags && tags.json && tags.json.mainTags) {
        yield put(setTagsCollection(tagsCollections.json))
      }

      const savedSearches = yield call(SavedSearchesApi.getSavedSearches())
      if (savedSearches.json && savedSearches.json.length) {
        yield put(setSavedSearches(savedSearches.json))
      }

      const settingsData = yield call(FiltersApi.getSettings())
      const settings = deserializeSidebarSettings(settingsData)
      yield put(setSidebarSettings(settings))

      const startups = yield call(SearchApi.search(initState.startups.filters))
      yield put(setSearchStartupsList(startups.json.startups))
      yield put(setNumberStartupsList(startups.json.startups_count))

      yield put(setApplicationState(appStates.ready))
    } else {
      const nbStartups = yield call(NbStartupsApi.nbStartups())
      yield put(setNbStartups(nbStartups.json.nbStartups))

      const tags = yield call(RequestInfoApi.getRequestTag())
      if (tags && tags.json && tags.json.mainTags) {
        yield put(setTags(tags.json))
      }

      const tagsCollections = yield call(RequestInfoApi.getTagCollection())
      if (tags && tags.json && tags.json.mainTags) {
        yield put(setTagsCollection(tagsCollections.json))
      }
    }
  } catch (e) {
    console.error("Throw error to sentry?", e)
    yield put(setApplicationState(appStates.error))
  }
}

function* loginAutomatically() {
  try {
    const email = window.localStorage.getItem("email")
    const password = window.localStorage.getItem("password")

    if (email && password) {
      const response = yield call(LoginApi.signIn({ email, password }))
      yield put(logIn(response))
    } else {
      yield put(setApplicationState(appStates.initial))
    }
  } catch (e) {
    yield put(setApplicationState(appStates.initial))
  }
}

function* startupTest() {
  const keepLoggedIn = window.localStorage.getItem("keepLoggedIn") === "true"

  if (keepLoggedIn) {
    yield call(loginAutomatically)
  } else {
    yield put(setApplicationState(appStates.initial))
  }
}

export function* startupSaga() {
  let search = window.location.search
  let params = new URLSearchParams(search)
  let tokenParams = params.get("tokenShow")
  try {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!tokenParams) {
      const settingsData = yield call(FiltersApi.getSettings())
      const settings = deserializeSidebarSettings(settingsData)
      yield put(setSidebarSettings(settings))
      const startups = yield call(SearchApi.search())
      yield put(setSearchStartupsList(startups.json.startups))
      yield put(setApplicationState(appStates.ready))
    }
  } catch (e) {
    console.error("Throw error to sentry?", e)
    yield put(setApplicationState(appStates.error))
  }
  yield takeEvery(LOG_IN, loadAppState)
  yield takeEvery("STARTUP", startupTest)
}
