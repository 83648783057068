// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Main_Container__7behG {\n  padding: 0px 50px 50px;\n  width: 100%; }\n\n.Main_Wrapper__24JjK {\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 300px 1fr; }\n", "",{"version":3,"sources":["webpack://src/pages/Main/Main.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW,EAAA;;AAGb;EACE,aAAa;EACb,cAAc;EACd,gCAAgC,EAAA","sourcesContent":[".Container {\n  padding: 0px 50px 50px;\n  width: 100%;\n}\n\n.Wrapper {\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 300px 1fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "Main_Container__7behG",
	"Wrapper": "Main_Wrapper__24JjK"
};
export default ___CSS_LOADER_EXPORT___;
