import React from "react"
import { useState } from "react"

import { useSelector } from "react-redux"
import Title from "src/components/Typography/Title"
import Sidebar from "./Sidebar"
import { YoutubeNotLogin } from "./youtubeNotLogin"
import CompanyList from "./CompanyList"
import ShareModalList from "./ShareModalList"

import classes from "./SearchStartups.module.scss"
import HighlightedText from "src/components/Typography/HighlightedText"
import Typography from "@material-ui/core/Typography"

function StartupsList() {
  const filteredStartupList = useSelector((state) => {
    return state.startups.specificScouting.json
      ? state.startups.specificScouting.json.startups
      : []
  })
  // eslint-disable-next-line no-extra-boolean-cast
  return <CompanyList list={filteredStartupList} />
}

function Scouting() {
  const logInState = useSelector((state) => state.view.loggedIn)
  const [open, setOpen] = useState(false)
  //const [startup, setStartup] = useState(null)

  const numberStartups = useSelector(
    (state) =>
      state.startups.specificScouting &&
      state.startups.specificScouting.json &&
      state.startups.specificScouting.json.startups_count
  )
  let search = window.location.search
  let params = new URLSearchParams(search)
  let tokenParams = params.get("tokenShow")

  const handleClose = () => {
    setOpen(true)
  }

  const onCloseTransitionEnd = () => {
    //  setStartup(null)
  }

  return (
    <div data-test-id="search-startups-wrapper">
      <Title>
        {logInState ? (
          numberStartups > 3490 ? (
            <div>
              There are <HighlightedText> {numberStartups || 0} </HighlightedText>{" "}
              startups in your scouting list.
            </div>
          ) : (
            <div>
              There are <HighlightedText> {numberStartups || 0} </HighlightedText>{" "}
              startups in your scouting list.
            </div>
          )
        ) : (
          <div>
            There are <HighlightedText> {numberStartups || 0} </HighlightedText>{" "}
            startups in your scouting list.
          </div>
        )}
      </Title>
      {logInState ? (
        <div className={classes.Wrapper}>
          <Sidebar />
          {numberStartups && numberStartups > 0 ? <StartupsList /> : ""}
        </div>
      ) : tokenParams && tokenParams.length === 20 ? (
        <div className={classes.Wrapper}>
          <Sidebar />
          <div>
            {numberStartups && numberStartups > 0 ? (
              <React.Fragment>
                <StartupsList />
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    style={{ width: "100%", textAlign: "center", padding: "20px" }}
                  >
                    <Typography
                      className={classes.Heading}
                      variant="h5"
                      style={{ marginBottom: "15px" }}
                    >
                      Product Demo
                    </Typography>
                    <iframe
                      width="95%"
                      height="315"
                      //src={OneStartupList[0].demo_video_link}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <YoutubeNotLogin />
      )}
      <ShareModalList
        open={open}
        //startup={startup}
        handleClose={handleClose}
        onCloseEnd={onCloseTransitionEnd}
      />
    </div>
  )
}

export default Scouting
