import { debounce, select, call, put } from "redux-saga/effects"
import { SET_FILTERS, SET_CATEGORY, EDIT_FILTERS } from "../startups/types"
import { getFilters } from "src/redux/selectors"
import { SearchApi } from "src/api"
import {
  setSearchStartupsList,
  setNumberStartupsList,
  setStartupListScouting,
} from "../startups/actions"

function* searchForStartups() {
  try {
    const filters = yield select(getFilters)
    const results = yield call(SearchApi.search(filters))
    yield put(setSearchStartupsList(results.json.startups))
    yield put(setNumberStartupsList(results.json.startups_count))
    yield put(setStartupListScouting(results.json.startups_filter))
  } catch (e) {
    console.error("err", e)
  }
}

export function* watchFiltersChange() {
  yield debounce(10, [SET_FILTERS, SET_CATEGORY, EDIT_FILTERS], searchForStartups)
}
