import React from "react"
import ReactDOM from "react-dom"

import App from "./pages/Main"
import AppProvider from "./AppProvider"

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
