export const SET_CATEGORY = "SET_CATEGORY"
export const SET_START_UPS = "SET_START_UPS"
export const SET_STARTUP_LIST = "SET_STARTUP_LIST"
export const TOGGLE_FAVOURITE = "TOGGLE_FAVOURITE"
export const SET_CREATED_RANGE = "SET_CREATED_RANGE"
export const SET_EMPLOYEES_RANGE = "SET_EMPLOYEES_RANGE"
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE"
export const SAVE_SEARCH_CRITERIA = "SAVE_SEARCH_CRITERIA"
export const DELETE_CRITERIA = "DELETE_CRITERIA"
export const SET_FILTERS = "SET_FILTERS"
export const SET_SEARCH_STARTUPS_LIST = "SET_SEARCH_STARTUPS_LIST"
export const SET_NUMBER_STARTUPS_LIST = "SET_NUMBER_STARTUPS_LIST"
export const SET_FAVOURITES = "SET_FAVOURITES"
export const SET_LIST_SCOUTINGS = "SET_LIST_SCOUTINGS"
export const SET_SPECIFIC_LIST_SCOUTINGS = "SET_SPECIFIC_LIST_SCOUTINGS"
export const SET_SPECIFIC_LIST_SCOUTINGS_ID = "SET_SPECIFIC_LIST_SCOUTINGS_ID"
export const SET_USER_SPECIFIC_LIST_SCOUTINGS = "SET_USER_SPECIFIC_LIST_SCOUTINGS"
export const SET_TAGS = "SET_TAGS"
export const SET_TAGS_COLLECTION = "SET_TAGS_COLLECTION"
export const SET_REQUEST_INFOS = "SET_REQUEST_INFOS"
export const SET_SAVED_SEARCHES = "SET_SAVED_SEARCHES"
export const WAIT_SEARCHES = "WAIT_SEARCHES"
export const EDIT_FILTERS = "EDIT_FILTERS"
export const TOGGLE_REQUESTED_STARTUP = "TOGGLE_REQUESTED_STARTUP"
export const GET_SIMILAR_STARTUP = "GET_SIMILAR_STARTUP"
