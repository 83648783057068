// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchStartups_Container__3cl1q {\n  padding: 10px 50px; }\n\n.SearchStartups_Wrapper__3BU6L {\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 300px 1fr;\n  grid-template-rows: max-content; }\n\n.SearchStartups_Title__2wDv5 {\n  text-align: center;\n  padding: 30px 0;\n  font-size: 54px;\n  font-style: normal;\n  font-weight: 100; }\n  .SearchStartups_Title__2wDv5 > span {\n    color: #103df5; }\n", "",{"version":3,"sources":["webpack://src/pages/Main/Scouting/SearchStartups.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA;;AAGpB;EACE,aAAa;EACb,cAAc;EACd,gCAAgC;EAChC,+BAA+B,EAAA;;AAGjC;EACE,kBAAkB;EAClB,eAAe;EAKf,eAAe;EACf,kBAAkB;EAClB,gBAAgB,EAAA;EATlB;IAKI,cAAc,EAAA","sourcesContent":[".Container {\n  padding: 10px 50px;\n}\n\n.Wrapper {\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 300px 1fr;\n  grid-template-rows: max-content;\n}\n\n.Title {\n  text-align: center;\n  padding: 30px 0;\n\n  > span {\n    color: #103df5;\n  }\n  font-size: 54px;\n  font-style: normal;\n  font-weight: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "SearchStartups_Container__3cl1q",
	"Wrapper": "SearchStartups_Wrapper__3BU6L",
	"Title": "SearchStartups_Title__2wDv5"
};
export default ___CSS_LOADER_EXPORT___;
