export const nbStartups = () => async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/nbstartups`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
  const data = await response.json()
  return data
  //if (filters.load === "new") return data;
  //return filterStartups(data, filters);
}
